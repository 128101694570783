import React from 'react';
import { Helmet } from 'react-helmet-async';

function EthicsCodex() {
  return (
    <div className="xContent p-box py-6">
      <Helmet>
        <title>Этический кодекс</title>
        <meta
        name="description"
        content="Этический кодекс члена Ассоциации Некоммерческого Партнерства 
        «Международная Ассоциация Маркетологов СНГ»"
        />
        <link rel="canonical" href="https://marketing-association.com/ethicscodex" />
      </Helmet>
        <div className="content p-20 wysiwyg">
        <div className="title is-size-3xl is-font-title-regular has-text-var-blue">Этический кодекс члена Ассоциации</div>
        <div className="title is-size-3xl has-text-var-blue">Мы формируем будущее маркетинга сегодня!</div>
        <p className="has-text-centered"><b>ЭТИЧЕСКИЕ ПРИНЦИПЫ ДЕЯТЕЛЬНОСТИ ЧЛЕНОВ</b></p>
        <b><p className="has-text-centered">Некоммерческого Партнерства 
        «Международная Ассоциация Маркетологов СНГ»</p></b>
        <ol>
            <li><span className="pad"><b>Профессиональная добросовестность.</b></span>
            Члены Ассоциации Маркетологов, осуществляя свою профессионапьную деятельность, стремятся не допускать недостоверность или ангажированность в целях, структуре, содержании или выводах по результатам этой деятельности, считая их проявление ударом по репутации не только самого специалиста, но и Ассоциации в целом.</li>
            <li><span className="pad"><b>Открытость.</b></span>
            Ассоциации открыта всем своим членам (а при наличии их рекомендаций — и другим профессионалам, представителям общественности) для поиска, обсуждения, апробации, оценки и продвижения как уже состоявшихся, так и появляющихся новых профессиональных идей, концепций, проектов и практик. Результаты этой работы становятся доступными для широкой общественности после официального утверждения Советом Ассоциации или ее Комитетами, при наличии у них соответствующих полномочий.</li>
            <li><span className="pad"><b>Сопричастность.</b></span>
            Члены Ассоциации своей активностью способствуют осуществлению принципов деятельности и проектов Ассоциации, позиционируют себя не только как профессионалов по месту своей постоянной профессиональной принадлежности, но и как членов Ассоциации через все доступные коммуникативные каналы содействуют повышению престижа Ассоциации, ее членов и проектов. Обнаружив публичное поношение деятельности Ассоциации или агрессивные высказывания и действия в отношении Ассоциации в целом или членов Ассоциации, они никогда не присоединятся к нападающим, а при необходимости и по возможности — выступят их оппонентом.</li>
            <li><span className="pad"><b>Профессиональная поддержка.</b></span>
            Члены Ассоциации считают нормой своего поведения квалифицированную и оперативную помощь коллегам по Ассоциации в решении профессиональных проблем. Если к члену Ассоциации обратился с вопросом, просьбой или предложением другой член Ассоциации, признается нормой в течение 36 часов подтвердить получение обращения и обозначить разумный срок, в течение которого будет дан ответ по существу.</li>
            <li><span className="pad"><b>Дисциплина выполнения поручений.</b></span>
            Если член Ассоциации добровольно принял на себя поручение Ассоциации или ее органов управления, он обязан выполнить его точно, качественно и в срок. Если же это стало невозможным, он обязан найти себе замену — другого члена Ассоциации и сообщить об этом Совету Ассоциации.</li>
            <li><span className="pad"><b>Толерантность.</b></span>
            Члены Ассоциации толерантны к проявлениям различий, являющихся следствием принадлежности к разным гендерным, национальным, конфессиональным, политическим, мировоззренческим и иным социумным группам, если эти проявления не противоречат действующему законодательству и не препятствуют реализации профессионального потенциала Ассоциации и ее членов.</li>
            <li><span className="pad"><b>Конструктивная критика.</b></span>
            Член Ассоциации имеет право на любую конструктивную критику внутри Ассоциации профессиональной деятельности и относящейся к ней активности любого члена Ассоциации, ее органов управления и Ассоциации в целом как организации, если эта критика не нарушает других норм и принципов деятельности Ассоциации.</li>
            <li><span className="pad"><b>Ответственность за нарушения этических принципов.</b></span> Всякое подтвержденное документами, свидетельствами членов Ассоциации или общественностью нарушение этих принципов является основанием для обсуждения на Совете Ассоциации и может повлечь за собой исключение члена Ассоциации из ее состава с информированием об этом общественности.</li>
        </ol>
        </div>
    </div>
  );
}

export default EthicsCodex;
