export const expertsData = [
    { id: 1, name: 'Ман Игорь Борисович',            country: 'Россия',           description: 'Digital Marketing & PR Manage',    year: 2018},
    { id: 2, name: 'Жуковский Сергей Романович',     country: 'Украина',          description: 'Директор по маркетингу',           year: 2018},
    { id: 3, name: 'Агаев Анвар Иглаевич',           country: 'Азербайджан',      description: 'Директор по бренду' ,              year: 2018},
    { id: 4, name: 'Саркисян Армен Александрович',   country: 'Армения',          description: 'Руководитель проекта',             year: 2018},
    { id: 5, name: 'Попов Максим Юрьевчич',          country: 'Беларусь',         description: 'Директор по маркетингу',           year: 2018},
    { id: 6, name: 'Алимбаев Данияр Ибрагимович',    country: 'Казахстан',        description: 'Директор по диджитал маркетингу',  year: 2018},
    { id: 7, name: 'Юсупова Азиза Имбулаевна',       country: 'Киргизия',         description: 'Директор по диджитал маркетингу',  year: 2018},
    { id: 8, name: 'Стратулат Ирина Владимировна',   country: 'Молдова',          description: 'Директор по бренду',               year: 2018},
    { id: 9, name: 'Рахимова Зарина Ивановна',       country: 'Таджикистан',      description: 'Директор по бренду',               year: 2018},
    { id: 10, name:'Какабаева Айгуль ',              country: 'Туркменистан',     description: 'Директор по диджитал маркетингу',  year: 2018},
    { id: 11, name:'Махмудова Шахзода ',             country: 'Узбекистан',       description: 'Директор по маркетингу',           year: 2018},
    { id: 12, name:'Карумидзе Нино ',                country: 'Грузия',           description: 'Digital Marketing & PR Manager',   year: 2018},

    { id: 13, name: 'Виноградов Пахом Денисович',   country:  'Украина',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 14, name: 'Соколов Пахом Владиленович',   country:  'Украина',     description: 'Директор по диджитал маркетингу',  year: 2018},
    { id: 15, name: 'Голубева Дарья Владимировна',   country: 'Россия',    description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 16, name: 'Лихачев Симон Владиславович',   country: 'Беларусь',    description: 'Руководитель проекта',  year: 2018},
    { id: 17, name: 'Васильева Людмила Вячеславовна',country: 'Украина',    description: 'Руководитель проекта',  year: 2018},
    { id: 18, name: 'Большаков Куприян Гавриилович', country: 'Россия',    description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 19, name: 'Архипова Фёкла Геннадиевна',   country:  'Россия',     description: 'Директор по маркетингу',  year: 2018},
    { id: 20, name: 'Горбунова Эмилия Даниловна',   country:  'Украина',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 21, name: 'Артемьев Прокофий Григорьевич',country:  'Украина',     description: 'Директор по маркетингу',  year: 2018},
    { id: 22, name: 'Дмитриев Марк Ермолаевич',     country:  'Армения',     description: 'Директор по бренду',  year: 2018},
    { id: 23, name: 'Доронина Алла Кирилловна',     country:  'Украина',     description: 'Директор по бренду',  year: 2018},
    { id: 24, name: 'Доронина Полина Робертовна',   country:  'Армения',     description: 'Директор по бренду',  year: 2018},

    { id: 25, name: 'Логинова Василиса Егоровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 26, name: 'Зимина Прасковья Егоровна',    country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 27, name: 'Зыкова Людмила Степановна',    country: 'Украина',     description: 'Директор по бренду',  year: 2018},
    { id: 28, name: 'Кудрявцев Корнил Измаилович',  country: 'Украина',     description: 'Директор по бренду',  year: 2018},
    { id: 29, name: 'Юдин Каллистрат Исидорович',   country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2018},
    { id: 30, name: 'Королева Галина Вадимовна',    country: 'Украина',     description: 'Директор по маркетингу',  year: 2018},
    { id: 31, name: 'Кудрявцев Глеб Дорофеевич',    country: 'Украина',     description: 'Директор по маркетингу',  year: 2018},
    { id: 32, name: 'Кулагин Кондрат Давидович',    country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2018},
    { id: 33, name: 'Новикова Пелагея Максимовна',  country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 34, name: 'Игнатьев Захар Марсович',      country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 35, name: 'Назарова Фаина Сергеевна',     country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 36, name: 'Некрасова Пелагея Тимофеевна', country: 'Казахстан',     description: 'Директор по диджитал маркетингу',  year: 2018},

    { id: 37, name: 'Аксенова Фаина Петровна',   country: 'Россия',     description: 'Руководитель проекта',  year: 2018},
    { id: 38, name: 'Рогов Авксентий Ефимович',   country: 'Грузия',     description: 'Директор по бренду',  year: 2018},
    { id: 39, name: 'Романова Глафира Артемовна',   country: 'Таджикистан',     description: 'Руководитель проекта',  year: 2018},
    { id: 40, name: 'Самсонов Касьян Эдгарович',   country: 'Киргизия',     description: 'Директор по бренду',  year: 2018},
    { id: 41, name: 'Сергеева Ольга Александровна',   country: 'Армения',     description: 'Директор по бренду',  year: 2018},
    { id: 42, name: 'Тетерин Мартьян Иосипович',   country: 'Россия',     description: 'Руководитель проекта',  year: 2018},
    { id: 43, name: 'Фадеева Евгения Владиславовна',   country: 'Грузия',     description: 'Руководитель проекта',  year: 2018},
    { id: 44, name: 'Сергеева Светлана Федоровна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2018},
    { id: 45, name: 'Ситников Наум Феодосьевич',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2018},
    { id: 46, name: 'Шубин Александр Филатович',   country: 'Азербайджан',     description: 'Директор по бренду',  year: 2018},
    { id: 47, name: 'Якушева Агата Филипповна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2018},
    { id: 48, name: 'Воронова Алевтина Харитоновна',   country: 'Армения',     description: 'Руководитель проекта',  year: 2018},

    { id: 49, name: 'Цветкова Евфросиния Леонидовна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 50, name: 'Шарапова Раиса Олеговна',   country: 'Молдова',     description: 'Digital Marketing & PR Manager',  year: 2018},
    { id: 51, name: 'Щербакова Раиса Вениаминовна',   country: 'Украина',     description: 'Руководитель проекта',  year: 2018},
    { id: 52, name: 'Смирнова Таисия Яковлевна',   country: 'Азербайджан',     description: 'Руководитель проекта',  year: 2018},
    { id: 53, name: 'Воробьев Любосмысл Якубович',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2018},
    { id: 54, name: 'Зыков Прохор Ааронович',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 55, name: 'Беляев Сигизмунд Аверьянович',   country: 'Армения',     description: 'Директор по бренду',  year: 2019},
    { id: 56, name: 'Владилен Артёмович Агафонов',   country: 'Украина',     description: 'Директор по бренду',  year: 2019},
    { id: 57, name: 'Денисова Нина Афанасьевна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 58, name: 'Корнилова Лидия Афанасьевна',   country: 'Украина',     description: 'Руководитель проекта',  year: 2019},
    { id: 59, name: 'Беляков Венедикт Устинович',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 60, name: 'Беляков Кондратий Юльевич',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2019},

    { id: 61, name: 'Борисова Ксения Леонидовна',   country: 'Россия',     description: 'Директор по бренду',  year: 2019},
    { id: 62, name: 'Волкова Фёкла Васильевна',   country: 'Азербайджан',     description: 'Директор по бренду',  year: 2019},
    { id: 63, name: 'Веселова Любовь Ильинична',   country: 'Казахстан',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 64, name: 'Данилов Кир Виленович',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 65, name: 'Гаврилов Всеслав Александрович',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 66, name: 'Комиссаров Мирон Германович',   country: 'Армения',     description: 'Директор по бренду',  year: 2019},
    { id: 67, name: 'Григорьев Дорофей Фокич',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2019},
    { id: 68, name: 'Маркова Акулина Григорьевна',   country: 'Азербайджан',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 69, name: 'Блинов Силантий Давидович',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 70, name: 'Яковлев Лонгин Давыдович',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2019},
    { id: 71, name: 'Шестаков Нифонт Данилович',   country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 72, name: 'Аксенов Виссарион Демидович',   country: 'Россия',     description: 'Руководитель проекта',  year: 2019},
    { id: 73, name: 'Емельянов Любомир Демидович',   country: 'Грузия',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 74, name: 'Калашников Леонид Егорович',   country: 'Россия',     description: 'Руководитель проекта',  year: 2019},
    { id: 75, name: 'Котов Карп Ерофеевич',   country: 'Киргизия',     description: 'Директор по маркетингу',  year: 2019},
    { id: 76, name: 'Журавлев Макар Феликсович',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 77, name: 'Зыков Конон Данилович',   country: 'Азербайджан',     description: 'Директор по бренду',  year: 2019},
    { id: 78, name: 'Кириллов Спиридон Игоревич',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 79, name: 'Уварова Надежда Игоревна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 80, name: 'Андроник Артурович Комиссаров',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 81, name: 'Кошелева Лидия Константиновна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 82, name: 'Красильников Агафон Афанасьевич',   country: 'Киргизия',     description: 'Руководитель проекта',  year: 2019},
    { id: 83, name: 'Лазарев Всеволод Венедиктович',   country: 'Украина',     description: 'Директор по бренду',  year: 2019},
    { id: 84, name: 'Тамара Тимуровна Ларионова',   country: 'Россия',     description: 'Руководитель проекта',  year: 2019},
    { id: 85, name: 'Лыткин Ипатий Измаилович',   country: 'Азербайджан',     description: 'Руководитель проекта',  year: 2019},
    { id: 86, name: 'Лыткин Фока Федотович',   country: 'Молдова',     description: 'Руководитель проекта',  year: 2019},
    { id: 87, name: 'Архипова Тамара Львовна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 88, name: 'Маслов Аркадий Ануфриевич',   country: 'Беларусь',     description: 'Руководитель проекта',  year: 2019},
    { id: 89, name: 'Маслов Дмитрий Викентьевич',   country: 'Россия',     description: 'Директор по бренду',  year: 2019},
    { id: 90, name: 'Морозова Акулина Святославовна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 91, name: 'Кононова Раиса Наумовна',   country: 'Грузия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 92, name: 'Некрасова Варвара Борисовна',   country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 93, name: 'Пахомова Виктория Станиславовна',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 94, name: 'Горшкова Раиса Святославовна',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2019},
    { id: 95, name: 'Ковалев Гурий Трифонович',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2019},
    { id: 96, name: 'Миронов Владилен Феофанович',   country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 97, name: 'Бобылев Сократ Филатович',   country: 'Россия',     description: 'Руководитель проекта',  year: 2019},
    { id: 98, name: 'Фомин Стоян Елисеевич',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2019},
    { id: 99, name: 'Харитонова Мария Тарасовна',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2019},
    { id: 100, name:'Хохлов Максим Ильич',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2019},
    { id: 101, name:'Чернов Мечислав Всеволодович',   country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 102, name:'Никитин Сидор Юлианович',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2019},
    { id: 103, name:'Макарова Милица Юльевна',   country: 'Азербайджан',     description: 'Директор по диджитал маркетингу',  year: 2019},
    { id: 104, name:'Лапина Лора Яковлевна',   country: 'Армения',      description: 'Руководитель проекта',   year: 2019},
    { id: 105, name: 'Борисов Януарий Авдеевич',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 106, name: 'Лебедева Марфа Александровна',   country: 'Таджикистан',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 107, name: 'Сергеева Анастасия Александровна',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 108, name: 'Щербаков Галактион Богданович',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 109, name: 'Мишина Татьяна Борисовна',   country: 'Молдова',     description: 'Руководитель проекта',  year: 2020},
    { id: 110, name: 'Субботин Кондратий Брониславович',   country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2020},
    { id: 111, name: 'Киселева Эмилия Вениаминовна',   country: 'Россия',     description: 'Руководитель проекта',  year: 2020},
    { id: 112, name: 'Вацлав Артурович Владимиров',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 113, name: 'Дементьев Кир Владиславович',   country: 'Таджикистан',     description: 'Директор по бренду',  year: 2020},
    { id: 114, name: 'Овчинников Милован Владиславович',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 115, name: 'Коновалов Арсений Гертрудович',   country: 'Украина',     description: 'Руководитель проекта',  year: 2020},
    { id: 116, name: 'Горбунов Самуил Фокич',   country: 'Азербайджан',     description: 'Директор по маркетингу',  year: 2020},
    { id: 117, name: 'Гуляев Богдан Вилорович',   country: 'Армения',     description: 'Руководитель проекта',  year: 2020},
    { id: 118, name: 'Дементьев Кир Давыдович',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 119, name: 'Евдокимова Анастасия Альбертовна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 120, name: 'Ефремова Ираида Геннадьевна',   country: 'Украина',     description: 'Руководитель проекта',  year: 2020},
    { id: 121, name: 'Князев Макар Ефремович',   country: 'Таджикистан',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 122, name: 'Бобылева Мария Ивановна',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 123, name: 'Лазарева Фёкла Ивановна',   country: 'Украина',     description: 'Директор по бренду',  year: 2020},
    { id: 124, name: 'Калашников Исай Игнатьевич',   country: 'Молдова',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 125, name: 'Анисимов Дорофей Изотович',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 126, name: 'Сергеева Ольга Ильинична',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2020},
    { id: 127, name: 'Карпов Ратибор Трифонович',   country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2020},
    { id: 128, name: 'Ефремова Наталья Кирилловна',   country: 'Россия',     description: 'Руководитель проекта',  year: 2020},
    { id: 129, name: 'Колесников Всеволод Юльевич',   country: 'Беларусь',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 130, name: 'Рябова Олимпиада Кузьминична',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2020},
    { id: 131, name: 'Суханова Алевтина Кузьминична',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 132, name: 'Лыткина Глафира Алексеевна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 133, name: 'Мамонтов Федот Трифонович',   country: 'Азербайджан',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 134, name: 'Матвеева Элеонора Артемовна',   country: 'Таджикистан',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 135, name: 'Нестеров Бажен Валерианович',   country: 'Казахстан',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 136, name: 'Никитина Кира Федоровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 137, name: 'Новикова Таисия Андреевна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 138, name: 'Романова Евгения Мироновна',   country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 139, name: 'Русакова Агата Никифоровна',   country: 'Казахстан',     description: 'Руководитель проекта',  year: 2020},
    { id: 140, name: 'Сергеев Харлампий Александрович',   country: 'Украина',     description: 'Руководитель проекта',  year: 2020},
    { id: 141, name: 'Копылова Анастасия Сергеевна',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 142, name: 'Тарасова Прасковья Григорьевна',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2020},
    { id: 143, name: 'Тарасовна Крюкова Агата',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 144, name: 'Титов Евсей Бориславович',   country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2020},
    { id: 145, name: 'Родионова Анжела Федоровна',   country: 'Беларусь',     description: 'Руководитель проекта',  year: 2020},
    { id: 146, name: 'Маслов Ювеналий Федосеевич',   country: 'Молдова',     description: 'Руководитель проекта',  year: 2020},
    { id: 147, name: 'Хохлова Анна Леоновна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2020},
    { id: 148, name: 'Партизпанян Айк Сергеевич',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2020},
    { id: 149, name: 'Ширяева Жанна Юрьевна',   country: 'Казахстан',     description: 'Директор по диджитал маркетингу',  year: 2020},
    { id: 150, name: 'Колесникова Тамара Юльевна',     country: 'Украина',     description: 'Директор по бренду',  year: 2020},
    { id: 151, name: 'Агафонов С амуил Зиновьевич',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 152, name: 'Аксенов Самуил Трофимович',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2021},
    { id: 153, name: 'Яковлева Антонина Александровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2021},
    { id: 154, name: 'Симонова Дарья Антоновна',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 155, name: 'Чернов Станимир Арсеньевич',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2021},
    { id: 156, name: 'Беляков Ким Анисимович',   country: 'Россия',     description: 'Директор по бренду',  year: 2021},
    { id: 157, name: 'Фролова Наталья Андреевна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2021},
    { id: 158, name: 'Брагина Любовь Руслановна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2021},
    { id: 159, name: 'Кононов Аггей Валентинович',   country: 'Россия',     description: 'Руководитель проекта',  year: 2021},
    { id: 160, name: 'Щукин Ратмир Виленович',   country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 161, name: 'Захарова Ирина Геннадиевна',   country: 'Беларусь',     description: 'Директор по бренду',  year: 2021},
    { id: 162, name: 'Нечаев Владислав Андреевич',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2021},
    { id: 163, name: 'Терентьев Влас Дмитриевич',   country: 'Казахстан',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 164, name: 'Денисова Екатерина Егоровна',   country: 'Киргизия',     description: 'Директор по бренду',  year: 2021},
    { id: 165, name: 'Захарова Алла Викторовна',   country: 'Таджикистан',     description: 'Руководитель проекта',  year: 2021},
    { id: 166, name: 'Романов Юлиан Измаилович',   country: 'Армения',     description: 'Директор по бренду',  year: 2021},
    { id: 167, name: 'Капустин Бронислав Васильевич',   country: 'Беларусь',     description: 'Директор по бренду',  year: 2021},
    { id: 168, name: 'Князева Раиса Архиповна',   country: 'Азербайджан',     description: 'Руководитель проекта',  year: 2021},
    { id: 169, name: 'Костин Сидор Данилович',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 170, name: 'Кузьмин Константин Игнатьевич',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 171, name: 'Евдокимова Любовь Макаровна',   country: 'Армения',     description: 'Директор по бренду',  year: 2021},
    { id: 172, name: 'Рожкова Алла Макаровна',   country: 'Беларусь',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 173, name: 'Максимова Юлия Викторовна',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2021},
    { id: 174, name: 'Мельников Евстафий Арсенович',   country: 'Азербайджан',     description: 'Директор по бренду',  year: 2021},
    { id: 175, name: 'Муравьев Ким Иосипович',   country: 'Таджикистан',     description: 'Руководитель проекта',  year: 2021},
    { id: 176, name: 'Степанова Маргарита Николаевна',   country: 'Украина',     description: 'Руководитель проекта',  year: 2021},
    { id: 177, name: 'Одинцова Елизавета Вадимовна',   country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2021},
    { id: 178, name: 'Савельева Кира Олеговна',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2021},
    { id: 179, name: 'Орлов Виктор Ефремович',   country: 'Армения',     description: 'Директор по бренду',  year: 2021},
    { id: 180, name: 'Титова Жанна Рудольфовна',   country: 'Россия',     description: 'Руководитель проекта',  year: 2021},
    { id: 181, name: 'Русакова Ангелина Степановна',   country: 'Армения',     description: 'Руководитель проекта',  year: 2021},
    { id: 182, name: 'Савин Мирон Ерофеевич',   country: 'Беларусь',     description: 'Руководитель проекта',  year: 2021},
    { id: 183, name: 'Тетерина Лора Святославовна',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 184, name: 'Симонова Октябрина Васильевна',   country: 'Азербайджан',     description: 'Директор по маркетингу',  year: 2021},
    { id: 185, name: 'Ситникова Валерия Георгиевна',   country: 'Таджикистан',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 186, name: 'Терентьева Марфа Тарасовна',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 187, name: 'Терентьева Агафья Аркадьевна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2021},
    { id: 188, name: 'Мухин Андрей Устинович',   country: 'Украина',     description: 'Руководитель проекта',  year: 2021},
    { id: 189, name: 'Виноградов Мстислав Фадеевич',   country: 'Украина',     description: 'Руководитель проекта',  year: 2021},
    { id: 190, name: 'Филатов Эраст Ефстафьевич',   country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 191, name: 'Александров Нифонт Харлампович',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2021},
    { id: 192, name: 'Чернова Вероника Алексеевна',   country: 'Украина',     description: 'Руководитель проекта',  year: 2021},
    { id: 193, name: 'Шаров Никон Евсеевич',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2021},
    { id: 194, name: 'Мартынов Вацлав Эдгарович',   country: 'Армения',     description: 'Руководитель проекта',  year: 2021},
    { id: 195, name: 'Федосеева Алина Эдуардовна',   country: 'Россия',     description: 'Директор по бренду',  year: 2021},
    { id: 196, name: 'Рожкова Елизавета Юльевна',   country: 'Россия',     description: 'Директор по бренду',  year: 2021},
    { id: 197, name: 'Яковлева Ульяна Викторовна',   country: 'Украина',     description: 'Директор по бренду',  year: 2021},
    { id: 198, name: 'Дементьев Авдей Андреевич',   country: 'Казахстан',     description: 'Директор по маркетингу',  year: 2022},
    { id: 199, name: 'Рожков Трофим Архипович',   country: 'Молдова',     description: 'Руководитель проекта',  year: 2022},
    { id: 200, name: 'Блинова Маргарита Архиповна',   country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2022},
    { id: 201, name: 'Блинова Пелагея Афанасьевна',   country: 'Казахстан',     description: 'Директор по маркетингу',  year: 2022},
    { id: 202, name: 'Андреева София Богдановна',   country: 'Армения',     description: 'Директор по бренду',  year: 2022},
    { id: 203, name: 'Борисова Антонина Богдановна',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2022},
    { id: 204, name: 'Галкина Марина Богдановна',   country: 'Беларусь',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 205, name: 'Силин Милован Бориславович',   country: 'Киргизия',     description: 'Руководитель проекта',  year: 2022},
    { id: 206, name: 'Воронцов Свят Трофимович',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2022},
    { id: 207, name: 'Воронцов Сильвестр Бориславович',   country: 'Таджикистан',     description: 'Руководитель проекта',  year: 2022},
    { id: 208, name: 'Никитин Спиридон Всеволодович',   country: 'Украина',     description: 'Директор по бренду',  year: 2022},
    { id: 209, name: 'Григорьева Александра Вениаминовна',   country: 'Армения',     description: 'Руководитель проекта',  year: 2022},
    { id: 210, name: 'Белоусова Юлия Григорьевна',   country: 'Россия',     description: 'Руководитель проекта',  year: 2022},
    { id: 211, name: 'Гришин Кир Фадеевич',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 212, name: 'Лобанов Олимпий Давыдович',   country: 'Украина',     description: 'Руководитель проекта',  year: 2022},
    { id: 213, name: 'Дорофеев Аким Борисович',   country: 'Молдова',     description: 'Директор по бренду',  year: 2022},
    { id: 214, name: 'Андреев Федосий Ермолаевич',   country: 'Украина',     description: 'Директор по бренду',  year: 2022},
    { id: 215, name: 'Капустин Игорь Ефремович',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2022},
    { id: 216, name: 'Максимов Аникита Ефстафьевич',   country: 'Казахстан',     description: 'Digital Marketing & PR Manager',  year: 2022},
    { id: 217, name: 'Тетерин Пахом Захарьевич',   country: 'Россия',     description: 'Директор по бренду',  year: 2022},
    { id: 218, name: 'Зыкова Татьяна Рудольфовна',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2022},
    { id: 219, name: 'Игнатова Ираида Эдуардовна',   country: 'Беларусь',     description: 'Директор по бренду',  year: 2022},
    { id: 220, name: 'Владимиров Никифор Иосипович',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2022},
    { id: 221, name: 'Исаков Карп Феодосьевич',   country: 'Беларусь',     description: 'Руководитель проекта',  year: 2022},
    { id: 222, name: 'Карпова Алевтина Афанасьевна',   country: 'Украина',     description: 'Директор по бренду',  year: 2022},
    { id: 223, name: 'Кириллов Аникей Абрамович',     country: 'Россия',     description: 'Руководитель проекта',  year: 2022},
    { id: 224, name: 'Князев Гордей Аксёнович',     country: 'Россия',     description: 'Директор по маркетингу',  year: 2022},
    { id: 225, name: 'Комаров Артемий Дмитриевич',   country: 'Россия',     description: 'Директор по бренду',  year: 2022},
    { id: 226, name: 'Кононов Игорь Фокич',          country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 227, name: 'Капустина Екатерина Леоновна',   country: 'Россия',     description: 'Директор по бренду',  year: 2022},
    { id: 228, name: 'Морозов Аникита Исидорович',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 229, name: 'Ершова Нинель Наумовна',         country: 'Молдова',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 230, name: 'Ситникова Вероника Никифоровна',   country: 'Беларусь',     description: 'Директор по бренду',  year: 2022},
    { id: 231, name: 'Белова Агафья Николаевна',        country: 'Россия',     description: 'Директор по бренду',  year: 2022},
    { id: 232, name: 'Кулакова Василиса Николаевна',   country: 'Таджикистан',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 233, name: 'Никонова Ксения Робертовна',     country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 234, name: 'Павлов Станимир Дорофеевич',     country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 235, name: 'Аксенова Нонна Семеновна',       country: 'Армения',     description: 'Директор по маркетингу',  year: 2022},
    { id: 236, name: 'Луковский Игорь Николаевич',          country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2022},
    { id: 237, name: 'Вишнякова Марфа Тимофеевна',     country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2022},
    { id: 238, name: 'Агафонова Акулина Федоровна',   country: 'Россия',     description: 'Директор по бренду',  year: 2022},
    { id: 239, name: 'Федосеев Ипполит Феофанович',   country: 'Беларусь',     description: 'Директор по маркетингу',  year: 2022},
    { id: 240, name: 'Ильин Адам Феофанович',          country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2022},
    { id: 241, name: 'Филатов Степан Афанасьевич',     country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 242, name: 'Панфилов Викентий Фролович',     country: 'Армения',     description: 'Директор по диджитал маркетингу',  year: 2022},
    { id: 243, name: 'Цветкова Надежда Никифоровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2022},
    { id: 244, name: 'Чернова Клавдия Олеговна',       country: 'Россия',     description: 'Директор по маркетингу',  year: 2022},
    { id: 245, name: 'Шарова Евпраксия Аркадьевна',    country: 'Россия',     description: 'Руководитель проекта',  year: 2022},
    { id: 246, name: 'Ермаков Сила Эдгарович',         country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2022},
    { id: 247, name: 'Мартынов Остап Юлианович',        country: 'Азербайджан',     description: 'Руководитель проекта',  year: 2022},
    { id: 248, name: 'Исаева Лариса Яковлевна',        country: 'Армения',     description: 'Директор по бренду',  year: 2022},
    { id: 249, name: 'Лихачев Руслан Якубович',        country: 'Молдова',     description: 'Директор по бренду',  year: 2022},
    { id: 250, name: 'Александрова Анна Вадимовна',   country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 251, name: 'Анисимова Майя Александровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 252, name: 'Абрамова Ольга Алексеевна',   country: 'Украина',     description: 'Директор по бренду',  year: 2023},
    { id: 253, name: 'Мишина Василиса Алексеевна',   country: 'Россия',     description: 'Директор по бренду',  year: 2023},
    { id: 254, name: 'Баранова Фаина Альбертовна',   country: 'Азербайджан',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 255, name: 'Зуева Клавдия Андреевна',   country: 'Украина',     description: 'Директор по бренду',  year: 2023},
    { id: 256, name: 'Баранова Антонина Александровна',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2023},
    { id: 257, name: 'Беляков Арефий Вячеславович',   country: 'Молдова',     description: 'Директор по бренду',  year: 2023},
    { id: 258, name: 'Цветкова Вероника Борисовна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 259, name: 'Сафонова Зинаида Валериевна',   country: 'Армения',     description: 'Директор по бренду',  year: 2023},
    { id: 260, name: 'Абрамов Анисим Вилорович',   country: 'Россия',     description: 'Директор по бренду',  year: 2023},
    { id: 261, name: 'Галкин Иосиф Фёдорович',   country: 'Молдова',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 262, name: 'Кулагин Милован Герасимович',   country: 'Россия',     description: 'Директор по бренду',  year: 2023},
    { id: 263, name: 'Григорьев Всемил Эдгарович',   country: 'Азербайджан',     description: 'Директор по маркетингу',  year: 2023},
    { id: 264, name: 'Капустин Юлиан Дмитриевич',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2023},
    { id: 265, name: 'Егоров Федор Ильясович',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2023},
    { id: 266, name: 'Самсонов Феликс Елисеевич',   country: 'Украина',     description: 'Директор по диджитал маркетингу',  year: 2023},
    { id: 267, name: 'Третьяков Роман Елисеевич',   country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 268, name: 'Ершова Акулина Святославовна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2023},
    { id: 269, name: 'Жданова Ия Викторовна',   country: 'Таджикистан',     description: 'Директор по маркетингу',  year: 2023},
    { id: 270, name: 'Журавлева Лукия Ивановна',   country: 'Россия',     description: 'Директор по диджитал маркетингу',  year: 2023},
    { id: 271, name: 'Кириллова Юлия Юрьевна',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2023},
    { id: 272, name: 'Козлова Фёкла Егоровна',   country: 'Россия',     description: 'Директор по бренду',  year: 2023},
    { id: 273, name: 'Мартынова Евдокия Петровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 274, name: 'Меркушева Клавдия Рубеновна',   country: 'Украина',     description: 'Директор по бренду',  year: 2023},
    { id: 275, name: 'Николаев Софрон Димитриевич',   country: 'Беларусь',     description: 'Директор по бренду',  year: 2023},
    { id: 276, name: 'Корнилова Фёкла Петровна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2023},
    { id: 277, name: 'Иванова Виктория Семеновна',   country: 'Армения',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 278, name: 'Сергеева Евфросиния Петровна',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2023},
    { id: 279, name: 'Субботина Ираида Яковлевна',   country: 'Россия',     description: 'Руководитель проекта',  year: 2023},
    { id: 280, name: 'Тетерин Кир Зиновьевич',   country: 'Таджикистан',     description: 'Директор по бренду',  year: 2023},
    { id: 281, name: 'Тимофеев Харитон Даниилович',   country: 'Украина',     description: 'Руководитель проекта',  year: 2023},
    { id: 282, name: 'Тимофеев Эдуард Давидович',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2023},
    { id: 283, name: 'Шилова Анжела Тимуровна',   country: 'Россия',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 284, name: 'Куликов Алексей Тихонович',   country: 'Армения',     description: 'Директор по бренду',  year: 2023},
    { id: 285, name: 'Третьякова Зинаида Валентиновна',   country: 'Украина',     description: 'Директор по бренду',  year: 2023},
    { id: 286, name: 'Савин Игнатий Устинович',   country: 'Украина',     description: 'Директор по маркетингу',  year: 2023},
    { id: 287, name: 'Игнатов Автоном Фадеевич',   country: 'Россия',     description: 'Директор по бренду',  year: 2023},
    { id: 288, name: 'Силин Куприян Федосеевич',   country: 'Россия',     description: 'Директор по бренду',  year: 2023},
    { id: 289, name: 'Алевтина Богдановна Филиппова',   country: 'Армения',     description: 'Руководитель проекта',  year: 2023},
    { id: 290, name: 'Петров Степан Фомич',   country: 'Украина',     description: 'Digital Marketing & PR Manager',  year: 2023},
    { id: 291, name: 'Савельев Кир Эдгардович',   country: 'Россия',     description: 'Директор по маркетингу',  year: 2023},
    { id: 292, name: 'Соловьева Агафья Яковлевна',   country: 'Армения',     description: 'Директор по маркетингу',  year: 2023},
    { id: 293, name: 'Зыков Трифон Ярославович',   country: 'Украина',     description: 'Руководитель проекта',  year: 2023},
    { id: 294, name: 'Фролов Герасим Ярославович',   country: 'Россия',     description: 'Руководитель проекта',  year: 2023},
    { id: 295, name: 'Семенов Самсон Августович',     country: 'Армения',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 296, name: 'Агафонова Виктория Ждановна',     country: 'Россия',    description: 'Директор по маркетингу',  year: 2024},
    { id: 297, name: 'Громова Прасковья Альбертовна',     country: 'Азербайджан',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 298, name: 'Третьякова Анна Анатольевна',     country: 'Украина',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 299, name: 'Афанасьев Поликарп Феофанович',     country: 'Азербайджан',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 300, name: 'Муравьев Андроник Афанасьевич',     country: 'Молдова',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 301, name: 'Беляев Емельян Дмитриевич',     country: 'Россия',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 302, name: 'Боброва Олимпиада Викторовна',     country: 'Россия',    description: 'Директор по бренду',  year: 2024},
    { id: 303, name: 'Жуков Амвросий Богданович',     country: 'Азербайджан',    description: 'Директор по маркетингу',  year: 2024},
    { id: 304, name: 'Назаров Любим Викентьевич',     country: 'Россия',    description: 'Директор по бренду',  year: 2024},
    { id: 305, name: 'Исаев Константин Владиленович',     country: 'Россия',    description: 'Директор по бренду',  year: 2024},
    { id: 306, name: 'Павлова Милица Геннадьевна',     country: 'Молдова',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 307, name: 'Гусева Полина Кирилловна',     country: 'Украина',    description: 'Руководитель проекта',  year: 2024},
    { id: 308, name: 'Давыдов Валерий Евстигнеевич',     country: 'Россия',    description: 'Директор по бренду',  year: 2024},
    { id: 309, name: 'Елисеев Сократ Демидович',     country: 'Россия',    description: 'Директор по маркетингу',  year: 2024},
    { id: 310, name: 'Дьячкова Наина Валентиновна',     country: 'Армения',    description: 'Директор по бренду',  year: 2024},
    { id: 311, name: 'Беляков Сергей Ефстафьевич',     country: 'Украина',    description: 'Директор по бренду',  year: 2024},
    { id: 312, name: 'Самойлова Валерия Ждановна',     country: 'Украина',    description: 'Директор по бренду',  year: 2024},
    { id: 313, name: 'Киселева Маргарита Ивановна',     country: 'Украина',    description: 'Директор по маркетингу',  year: 2024},
    { id: 314, name: 'Зыкова Нонна Игоревна',     country: 'Россия',    description: 'Руководитель проекта',  year: 2024},
    { id: 315, name: 'Данилова Регина Ильинична',     country: 'Таджикистан',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 316, name: 'Козлов Евгений Иосифович',     country: 'Украина',    description: 'Директор по бренду',  year: 2024},
    { id: 317, name: 'Исаков Лев Валерьевич',     country: 'Азербайджан',    description: 'Директор по маркетингу',  year: 2024},
    { id: 318, name: 'Калинина Октябрина Егоровна',     country: 'Россия',    description: 'Директор по маркетингу',  year: 2024},
    { id: 319, name: 'Веселова Александра Кирилловна',     country: 'Россия',    description: 'Руководитель проекта',  year: 2024},
    { id: 320, name: 'Крюкова Алевтина Никифоровна',     country: 'Россия',    description: 'Директор по бренду',  year: 2024},
    { id: 321, name: 'Куликова Зоя Яковлевна',     country: 'Россия',    description: 'Руководитель проекта',  year: 2024},
    { id: 322, name: 'Лобанов Викентий Матвеевич',     country: 'Беларусь',    description: 'Руководитель проекта',  year: 2024},
    { id: 323, name: 'Меркушев Наркис Гавриилович',     country: 'Армения',    description: 'Директор по бренду',  year: 2024},
    { id: 324, name: 'Меркушева Агафья Тарасовна',     country: 'Казахстан',    description: 'Директор по маркетингу',  year: 2024},
    { id: 325, name: 'З Михеева оя Владиславовна',     country: 'Армения',    description: 'Директор по маркетингу',  year: 2024},
    { id: 326, name: ' Никитина Милица Александровна',     country: 'Беларусь',    description: 'Директор по бренду',  year: 2024},
    { id: 327, name: 'Панфилова Наталья Оскаровна',     country: 'Россия',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 328, name: 'Павлова Ульяна Альбертовна',     country: 'Россия',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 329, name: 'Панов Гремислав Харлампович',     country: 'Молдова',    description: 'Директор по маркетингу',  year: 2024},
    { id: 330, name: 'Петухов Руслан Евстигнеевич',     country: 'Украина',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 331, name: 'Рыбакова Нонна Тимуровна',     country: 'Россия',    description: 'Директор по маркетингу',  year: 2024},
    { id: 332, name: 'Самсонова Людмила Яковлевна',     country: 'Азербайджан',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 333, name: 'Никитина Агафья Станиславовна',     country: 'Украина',    description: 'Руководитель проекта',  year: 2024},
    { id: 334, name: 'Синклитикия Федоровна Стрелкова',     country: 'Украина',    description: 'Директор по бренду',  year: 2024},
    { id: 335, name: 'Тарасов Евстигней Харламович',     country: 'Украина',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 336, name: 'Харитонов Венедикт Тихонович',     country: 'Беларусь',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 337, name: 'Трофимова Алла Станиславовна',     country: 'Армения',    description: 'Директор по бренду',  year: 2024},
    { id: 338, name: 'Рожкова Агата Федоровна',     country: 'Армения',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 339, name: 'Дьячков Иларион Федотович',     country: 'Киргизия',    description: 'Руководитель проекта',  year: 2024},
    { id: 340, name: 'Исаев Гурий Феофанович',     country: 'Беларусь',    description: 'Директор по маркетингу',  year: 2024},
    { id: 341, name: 'Кудряшов Валерий Эдуардович',     country: 'Таджикистан',    description: 'Директор по диджитал маркетингу',  year: 2024},
    { id: 342, name: 'Яковлев Агафон Тихонович',     country: 'Казахстан',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 343, name: 'Кулакова Лидия Яковлевна',     country: 'Азербайджан',    description: 'Директор по бренду',  year: 2024},
    { id: 344, name: 'Воронцов Яков Якубович',     country: 'Россия',    description: 'Руководитель проекта',  year: 2024},
    { id: 345, name: 'Елисеев Николай Якубович',     country: 'Армения',    description: 'Digital Marketing & PR Manager',  year: 2024},
    { id: 346, name: 'Кириллова Оксана Игоревна',     country: 'Россия',    description: 'Эксперт по международному стратегическому маркетингу',  year: 2024},
  ];
