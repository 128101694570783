

function ArticlePage08() {

    return (
      <div className="xContent p-box py-6">
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Чёрная пятница</h1>
        <div className="content wysiwyg">
        <p className='has-text-centered p-style p-1'>Чёрная пятница. Как продать. Как не купить.</p>
        <p>…Телефон пухнет от смс и вотсап от предложений что-либо купить со скидками, сообщения сыплются одно за другим. Глаза разбегаются и мелькает мысль: а, может, это всё-таки мне нужно, а?..</p>
        <p>В Чёрную пятницу журналисты кидаются звонить маркетологам и задавать вопросы об этом явлении. Автор этих строк не стала исключением, получив накануне в этот день около 10 звонков. Причём все корреспонденты желали либо получить ответ сразу по телефону, либо чтобы маркетолог приехал в телестудию, либо чтобы прошёлся по магазину/рынку с комментариями на камеру, либо чтобы оказался на связи с радиостанцией в означенный час.</p>
        <p>Ловко увернувшись от поездки на телестудию, прогулки по супермаркету с тем, чтобы дать комментарии, и телефонного комментария для радиостанции, автор строк пришла к простым, лежащим на поверхности выводам, которыми и решила поделиться.</p>
        <p className='p-1'>Чёрная пятница – это феномен психологический.</p>
        <p>Продавцы в Чёрную пятницу:</p>
        <p>- хотят разгрузить склады и сбыть товар, чтобы, основываясь на тяге покупателей к новизне, загрузить новыми товарами склады и способствовать созданию очередного тренда;</p>
        <p>- желают повысить продажи, если продажи затормозились;</p>
        <p>- рассчитывают на то, что людям свойственно покупать то (иногда якобы) дорогое, что дешёво (попросту на человеческую жадность);</p>
        <p>- рассчитывают на зависимость потребителей от чужого мнения и рекламы. Действительно, многие люди зависимы от этих влияний;</p>
        <p>- играют на обычных человеческих мотивах: рациональных и иррациональных (купить из соображений престижа известный бренд, запасти впрок, иметь много чего-либо и пр., и пр.);</p>
        <p>- рассчитывают на… климат. В России, где ноябрь самый тёмный и безрадостный месяц, Чёрная пятница на руку продавцам, потому что покупатели таким образом утешаются шоппингом, радуются скидкам (то есть получают положительные эмоции), а также просто развлекаются в тёмные дни;</p>
        <p>- возможность для некоторых потребителей выбросить агрессию, жажда борьбы (пусть и в драке за какую-то скидку). В США известны случае, когда люди убивали друг друга по-настоящему за очередь, парковку или скидку;</p>
        <p>- архетипическое мышление поколения 70-х–начала 90-х, когда ничего не было на прилавках, был тотальный дефицит. Это запечатлелось в подсознании, и некоторые покупатели хотят запастись товарами, как хомяк или белка;</p>
        <p>- плановая покупка. Покупателю было нужно, а тут – Чёрная пятница. Скидка очень мотивирует нерешительных или жадных;</p>
        <p className='p-1'>- продвижение бренда путём создания ажиотажа в Чёрную пятницу.</p>
        <p>В Чёрную пятницу покупатели могут задать СЕБЕ внятные вопросы, чтобы не стать её жертвой:</p>
        <p>- Планировал ли я покупать это в ближайшее врем</p>
        <p>- Нужно ли мне это сейчас?</p>
        <p>- Действительно ли это понадобится мне в будущем?</p>
        <p>- Есть ли у меня место в квартире/доме для очередной лишней вещи?</p>
        <p>- Нет ли у меня уже чего-либо подобного?</p>
        <p>- Стоит ли терять время на драку в очереди?</p>
        <p>- Нужно ли вставать в 6 утра, чтобы первым с боем попасть в магазин?</p>
        <p>- Так ли хорош этот товар в действительности, чтобы его покупать?</p>
        <p>- Экологичен ли этот товар? Разлагается ли он? Не засорит ли он планету, на которой живу я и мои дети?</p>
        <p className='p-1'>- А не являюсь ли я жертвой манипуляции?</p>
        <p>Что могут сделать маркетологи в зависимости от того, хотят ли они продвинуть товар и повысить продажи:</p>
        <p>- обладать широкими познаниями из области культуры и науки: литературы, живописи, музыки, кино, географии, этносов, возрастной психологии, цветоведения и многими другими, гуманитарными представлениями, экологическим мышлением и отчётливым пониманием целевой аудитории и референтной группы. И строить продвижение именно на этом, в том числе в Чёрную пятницу в контексте маркетинга.</p>
        <p>- не полагаться только на отменные навыки продаж, знания продвижения товара и услуг и обладать суперкреативным мышлением, накрепко связанным с высокими технологиями.</p>
        <p>- и самое главное: смотреть на товар глазами потребителя/покупателя, а не исходя только из собственных представлений о том, как обязан (!), почему-то, по мнению маркетолога, думать покупатель. В психологии это называется «проекция – тенденция наделять другого человека своими мыслями, настроениями, идеями, словом, считать человека подобным себе».</p>
        <p>Почему-то в ажиотаже брендинга маркетологи об этом забывают.</p>
        </div>
      </div>
    );
  }

  export default ArticlePage08;
