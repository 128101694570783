

function ArticlePage06() {
    return (
      <div className="xContent p-box py-6">
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Agile-маркетинг и 5 антикризисных маркетинговых стратегий 2021 в автобизнесе</h1>
        <div className="content wysiwyg">
        <p className='has-text-centered p-style p-1'>Agile-маркетинг и 5 антикризисных маркетинговых стратегий 2021 в автобизнесе (на примере Автохолдинга «Барс»)</p>
        <p>Уход с рынка многих западных брендов или приостановка их работы в России на неопределенный срок позволил переосмыслить многие процессы, а также стать лучше и сильнее.</p>
        <p>После шести месяцев неопределённости кто-то  , активно смотрит в сторону китайского автопрома, кто продаёт или диверсифицирует бизнес, а кто-то  продолжает нести ответственность перед клиентами и делать качественно свою работу, вне зависимости от обстоятельств, места и времени. Потому что комфорт клиентов – приоритет, любовь клиентов – самое важное, что должно быть, в мыслях компаний, которые хотят сохранить и приумножить.</p>
        <p>Работа в нестабильной среде позволила быстрее мыслить и тестировать гипотезы в Автохолдинге «Барс», в которой Agile-маркетинг и получил своё признание.</p>
        <p>Agile-маркетинг – это подход к маркетингу с использованием принципов и практик методологии Agile. Он подразумевает постоянное тестирование гипотез и работу малыми итерациями.</p>
        <p>Компания выбрала путь формирования идентичности собственного бренда, курс на лояльность потребителей и создание мощного комьюнити, за счёт внедрения «контента впечатлений» в каналы коммуникации.</p>
        <p>Ведь, лояльные клиенты — это то, что в первую очередь поможет пережить сложившуюся ситуацию и, возможно, выйти из неё победителем. </p>
        <p>Далее рассмотрим, какие антикризисные стратегии внедряет Автохолдинг «Барс».</p>
        <p><b>1. Разработка и внедрение концепции бренда Автохолдинга «Барс»</b></p>
        <p>С марта 2021 года, автодилеры встали перед необходимостью развивать собственные бренды, о чём ранее не задумывались. В марте 2020 года под моим руководством, совместно с командой отдела маркетинга мы начали работу над концепцией бренда. От проведения полномасштабных исследований, разработки концепции бренда, до создания бренд-персонажа.</p>
        <p>Мы понимали, что в основе концепции должна быть современная автоэкосистема, которая отражала бы наши преимущества: высокий уровень обслуживания, удобные условия, сервисы с целью создания положительных впечатлений. В марте мы закончили работу над концепцией «Территория семьи» Автохолдинга „Барс“ и приступили к её внедрению. От проведения event-мероприятий, до интеграции ценностей автохолдинга в контент-маркетинг и внутренние коммуникации:</p>
        <ol><li>Event-мероприятия</li>
        <li>Брендирование авто сотрудников</li>
        <li>Разработка бренд-персонажа</li>
        <li>Баннеры и наружная реклама</li>
        <li>Контент впечатлений в SMM</li>
        <li>Создание мощного комьюнити «Территория семьи Барс»</li>
        </ol>
        <p><b>2. Внутрифирменный маркетинг и развитие HR-бренда компании</b></p>
        <p>Помимо формирования идентичности бренда извне, компания занимается внутренним маркетингом. Концепция «Территория семьи» была успешно интегрирована в корпоративную культуру и стала идеологией компании. Во время проработки этапов внедрения концепции сформировался пул внутренних амбассадоров бренда, которые захотели брендировать автомобили. Также, мы сделали корпоративный телеграмм канал дайджест „Территория Семьи Барс“, где каждый день публикуем новости от каждого ДЦ.</p>
        <p>Были оформлены ценности концепции в столовых, а также были проведены мероприятия по тимбилдингу в каждом ДЦ в честь юбилея компании.</p>
        <p><b>3. Оптимизация отдела маркетинга и бизнес-процессов</b></p>
        <p>В апреле 2021 u было принято решение оптимизировать отдел маркетинга, таким образом, мы перераспределили работу примерно половины маркетологов, а в июне внедрили систему оценки эффективности специалистов, завязанную на KPI и показателях performance-маркетинга.</p>
        <p><b>4. Формирование комьюнити бренда Автохолдинга «Барс» за счет event-мероприятий и в социальных сетях</b></p>
        <p>Нашей основной задачей является формирование сильного комьюнити вокруг бренда автохолдинга и каждого ДЦ. Цель №1 — стать Lovermark для своих клиентов, создавать брендинг впечатлений, где центром является клиент, его комфорт и впечатления. Создание мощного комьюнити бренда в социальных сетях осуществляется за счёт планирования и постоянного тестирования гипотез коммуникационной стратегии, а также офлайновых event-мероприятий, подарков, розыгрышей, программ лояльности, а также внутреннего маркетинга (тимбилдинг, корпоративный дайджест, бренд-персонаж).</p>
        <p><b>5. Грамотные коммуникационные стратегии в SMM</b></p>
        <p>В марте 2021 года наступила новая SMM реальность в России, которая позволила, нам стать лучше и создавать, ещё более впечатляющий контент для наших клиентов, комьюнити приверженцев к бренду (авто, наших дилерских центров и самого холдинга) на различных площадках. Применив стратегию мульти-блогинга и стратегию вовлечённого контента впечатлений, к лету мы выбрали для себя приоритетные сети и сети второго порядка.</p>
        <p>Каждый месяц осуществляется планирование и корректировка SMM-стратегии (kpi, тестировании гипотез, планирование контента), постановка новых задач и апробирование новых инструментов контента впечатлений (блогерский формат, видео-маркетинг и нейро-копирайтинг), а также интеграция концепции бренда в контент-маркетинг.</p>
        <p>Осознанное планирование контента мы начали ещё с лета 2021, это позволило нам хорошо прирастать в запрещенном Инстаграм и Тик Ток и набирать миллионные просмотры. Также, по версии BMW Group, нам присвоили титул «Короли соцсетей».</p>
        <p>Внедрение концепции бренда автохолдинга «Территория семьи», геймификации, грамотного, видео-маркетинга, бренд-сторителлинга, создания пула амбассадоров в наши социальные сети повысило вовлеченность наших подписчиков. Каждый месяц мы апробируем и внедряем новые тренды. Поэтому рекомендую обратить внимание на улучшение качества контента и коммуникативной стратегии в целом.</p>
        <p><b>Выводы:</b></p>
        <p>В заключение, хочу подчеркнуть, что большинство автодилеров по всей стране неизбежно встанут на путь подобных изменений, а данные стратегии и наш опыт сможет помочь в этом. В условиях новой реальности, основная задача российских компаний – выстоять. </p>
        </div>
      </div>
    );
  }

  export default ArticlePage06;
