

function ArticlePage03() {
    return (
      <div className="xContent p-box py-6">
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue p-1">Agile-маркетинг и 5 антикризисных маркетинговых стратегий 2022 в автобизнесе</h1>
        <div className="content wysiwyg">
        <p>Инфлюенс-маркетинг — популярный и эффективный инструмент в B2C-сегменте, поскольку действительно помогает увеличить продажи. Но в B2B-сегменте авторитетное «сарафанное радио» работает несколько иначе. Тем не менее западные компании активно привлекают консультантов для развития в этом направлении.</p>
        <p>Директор по маркетингу американского медицинского стартапа MemoryMD Ирина Назарова в своей колонке рассказывает о своем опыте такого сотрудничества — где искать экспертов, какой формат коммуникации наиболее комфортный для них и почему нужны только честные отзывы.</p>
        <p><b>Как найти своих инфлюенсеров</b></p>
        <p>Возьмем любой медицинский стартап. Инфлюенсерами для такой компании могут быть врачи или ученые с весомым авторитетом и экспертизой. Нам повезло — когда стартап запускался, мы наняли американского консультанта по продажам и маркетингу с большим опытом работы в медицинских компаниях. У него были хорошие связи со многими известными врачами. Когда мы задумались о формировании медицинского консультативного совета (Medical advisory board), он помог нам выйти на связь с тремя экспертами, которые и стали нашими инфлюенсерами.</p>
        <p><b>Если вы выходите на американский рынок, найдите консультанта со связями именно в вашей сфере.</b></p>
        <p>Здесь этот подход очень распространен, и консультантов на рынке немало. Если же вы решите искать и договариваться о партнерстве с инфлюенсерами самостоятельно, то хороший канал для этого — LinkedIn. Ищите тех экспертов, у кого в описании профиля указан релевантный опыт.</p>
        <p>Рассчитывать на то, что врачи сидят в социальных сетях, постят информацию о продуктах и читают, что написали другие, — наивно. Такая же ситуация и в большинстве B2B-сфер. В поиске инфлюенсеров для них нам очень помогли отраслевые выставки и конференции, где нужно общаться и знакомиться, демонстрировать продукт.</p>
        <p>Когда вы найдете своих лидеров мнений, стройте с ними долгосрочные отношения. По сути, наши инфлюенсеры — это часть нашей команды, ведь мы сотрудничаем с ними на постоянной основе.</p>
        <p><b>Модель работы с инфлюенсером</b></p>
        <p>Схема работы с американскими инфлюенсерами такова: вначале мы отправили каждому нашу продукцию, дали время на ознакомление тестирование. После этого встретились с этими специалистами на конференциях и записали видеоинтервью о продукте и его использовании. Это было очень полезно не только с точки зрения развития influence-маркетинга, но и как фидбек пользователя, необходимый для развития продукта.</p>
        <p><b>Мы никак не влияли на отзывы, не прописывали заранее месседжи — всё было абсолютно честно, это важный этический момент в медицинской отрасли. Однако я как маркетолог подготовила для интервью вопросы, акцентирующие внимание на сильных сторонах нашего продукта.</b></p>
        <p>В дальнейшем мы публикуем такие интервью на своем сайте и в соцсетях, включаем в рассылку, используем их в качестве ответов на вопросы от потенциальных клиентов. А наше PR-агентство использует их в питчах для медиа и добавляет в образовательный контент.</p>
        <p><b>Больше, чем медийный инфлюенсер</b></p>
        <p>Когда при разработке нового продукта — детской гарнитуры для энцефалограммы — мы получили обратную связь от нашего эксперта, детского невролога, то внесли корректировки в продукт. После релиза продукта этот врач стал главным спикером в наших материалах. Сейчас он курирует клиническое исследование продукта. Для меня этот кейс — идеальная модель работы с инфлюенсером в моей отрасли.</p>
        <p>Второй бонус такого сотрудничества — это возможность опереться на экспертизу инфлюенсера как на референс при продаже продукта новому клиенту. Один из первых вопросов потенциального покупателя: «Кто уже пользуется этим продуктом?». В 90% случаев американский врач свяжется с тем, кого вы назовете, и спросит, действительно ли это хороший продукт. Мы всегда ссылаемся на наших экспертов и рассчитываем на их честный отзыв.</p>
        <p>Третий момент — при таком формате сотрудничества могут приходить новые инфлюенсеры, которые увидят контент с «вашими» врачами.</p>
        <p><b>Формат сотрудничества</b></p>
        <p>Американские медицинские инфлюенсеры не всегда работают за деньги. Врачи с большой вероятностью зарабатывают хорошо. Поэтому гонорар будет для них менее важен, чем имидж доктора, который имеет отношение к инновациям. Обычная практика для США — предложить эксперту не деньги, а акции компании в обмен на сотрудничество, и мы используем такой формат.</p>
        <p>Выступления на конференциях или комментарии для материалов не оплачиваются, как и курирование клинического испытания. Но компания заплатит за проведение такого исследования медицинскому учреждению (госпиталю), где оно будет проходить. Цены зависят от объема работ (например, сколько участников исследований необходимо задействовать).</p>
        <p><b>Наши отношения основаны исключительно на доверии. Мы не предъявляем каких-либо требований нашим экспертам, не ставим KPI.</b></p>
        <p>Формат общения должен быть очень мягким, иначе партнер уйдет, еще и сообщит коллегам: «Не работайте с этим стартапом, они не умеют коммуницировать».</p>
        <p>Не нужно диктовать, что делать, нельзя давить. Например, мы не требуем, чтобы доктор делал посты в социальных сетях. Это принципиальное отличие от сотрудничества в B2C, которое основано на постах инфлюенсеров на их страницах.</p>
        <p><b>Чек-лист по работе с инфлюенсерами в B2B:</b></p>
        <ol><li>Наймите консультанта, который поможет вам выйти на инфлюенсеров.</li>
        <li>Дайте продукт на тест и попросите фидбек об использовании.</li>
        <li>Запишите интервью с честным отзывом. Снимайте видеоконтент, особенно если есть возможность показать, как работает продукт.</li>
        <li>Старайтесь развивать долгосрочные отношения, делайте эксперта частью команды.</li>
        <li>Выстраивайте честные и прозрачные отношения.</li>
        <li>Предлагайте акции компании как вознаграждение.</li>
        <li>Не просите постов, не давите на В2В-экспертов. Дайте им возможности быть спикерами, для вашей аудитории это будет иметь большую ценность.</li>
        </ol>
        </div>
      </div>
    );
  }

  export default ArticlePage03;
