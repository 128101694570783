import React, { useEffect } from 'react';
import OrderImg from '../images/order.png'
import { Helmet } from 'react-helmet-async';

function Order() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="xContent p-box py-6">
          <Helmet>
        <title>Орден «ЗА ЗАСЛУГИ В МАРКЕТИНГЕ»</title>
        <meta
        name="description"
        content='Орден "За заслуги в маркетинге" вручается выдающимся маркетологам за личные высокие достижения, которые оказали значительное влияние на рост и развитие маркетинга'
        />
        <link rel="canonical" href="https://marketing-association.com/order" />
      </Helmet>
              <div className="title is-font-title-regular has-text-centered has-text-var-blue">ОРДЕН «ЗА ЗАСЛУГИ В МАРКЕТИНГЕ»</div>
        <div className="content wysiwyg">
        <div class="block">
    <div class="orderImage"><img src={OrderImg} alt="Order" /></div>
    </div>
    <p className="is-italic has-text-left">Орденский знак «За заслуги в маркетинге» утверждён 18 декабря 2018 года</p>
      <p className="has-text-left">Советом Международной Ассоциации Маркетологов СНГ</p>
      <p className="has-text-left p-style">Орден "За заслуги в маркетинге" вручается выдающимся маркетологам за личные высокие достижения, которые оказали значительное влияние на рост и развитие маркетинговой отрасли в своих странах и за её пределами. Личные достижения могут включать:</p>
      <ul className="criteriaList">
    <li>Запуск прорывных маркетинговых проектов, которые стали эталоном в индустрии.</li>
      <li>Разработку и реализацию масштабных рекламных кампаний с высоким уровнем охвата и вовлеченности целевой аудитории.</li>
      <li>Создание уникальных и инновационных продуктов или услуг, которые изменяют рыночную динамику.</li>
      <li>Построение и укрепление бренда, превращение его в лидера мнений и доверительный символ для потребителей.</li>
      <li>Получение признания в маркетинговом сообществе за вклад в этику, стандарты практики и прозрачность в работе.</li>
      </ul>
      <p>Ежегодное вручение Орденов "За заслуги в маркетинге" представляет собой знаковое событие для профессионального сообщества маркетологов из стран СНГ, включая Россию, Украину, Азербайджан, Армению, Беларусь, Казахстан, Киргизию, Молдову, Таджикистан, Туркменистан, Узбекистан и Грузию. Эти награды служат признанием самых значимых достижений в области маркетинга на просторах Содружества Независимых Государств.</p>
      <p>Ежегодно вручается только пять Орденов в год. Это говорит о том, что только самые выдающиеся достижения и вклад в профессию будут отмечены, и каждый лауреат может быть уверен, что его или ее работы действительно имеют исключительное значение.</p>
      <p>Кандидаты на получение Ордена проходят тщательный отбор на основе их профессиональных достижений, включая вклад в теорию маркетинга, успешные маркетинговые кампании, инновации и практические результаты, которые повлияли на рыночные позиции компаний и организаций.</p>
      <p>Поскольку страны СНГ имеют разные экономические и культурные особенности, Орден отражает успехи маркетологов в разнообразных условиях и на различных рынках, что добавляет международного веса к их профессиональным достижениям.</p>
      <p>Церемония награждения представляет собой значимое событие, которое собирает ведущих маркетологов из всех уголков СНГ, способствуя обмену знаниями и опытом между различными странами и культурами.</p>
      <p>Орден "За заслуги в маркетинге" подчеркивает не только персональные достижения, но и вклад лауреатов в развитие и процветание маркетинговой отрасли на региональном уровне, что имеет далеко идущие положительные последствия для экономики и бизнеса в целом.</p>
      <p>Значимость и престиж ордена обусловлены несколькими факторами:</p>
      <ul className="criteriaList">
    <li><b>Международное признание:</b> Орден является международной наградой, которая признается во многих странах и отражает международные стандарты профессионализма и успеха.</li>
      <li><b>Профессиональная ценность:</b> Получение такой награды является подтверждением высокой квалификации, таланта и инновационного подхода к маркетингу, что способствовует карьерному росту и расширению профессиональных горизонтов.</li>
      <li><b>Эксклюзивность:</b> Обладателем такой высокой награды может стать только выдающийся маркетолог, что делает её получение событием, подчеркивающим индивидуальную значимость и эксклюзивность вклада лауреата.</li>
      <li><b>Общественное признание:</b> Орден подтверждает не только профессиональный вклад, но и социальную значимость работы лауреата, его влияние на экономическое и культурное развитие общества.</li>
      <li><b>Средство мотивации:</b> Награда служит стимулом для других специалистов в сфере маркетинга стремиться к высоким достижениям и развивать отрасль.</li>
      </ul>
      <p>Орден "За заслуги в маркетинге" является символом высочайших стандартов в маркетинговой деятельности, внесения индивидуального значимого вклада в развитие отрасли, что делает его одной из наиболее престижных наград в маркетинговой сфере в таких странах как Россия, Украина, Азербайджан, Армения, Беларусь, Казахстан, Киргизия, Молдова, Таджикистан, Туркменистан, Узбекистан, Грузия</p>
      <p>Международная Ассоциация Маркетологов ежегодно награждает Отличительным орденским знаком «За заслуги в маркетинге» только пять самых выдающихся маркетологов в пяти номинациях:</p>
      <ol className="criteriaList">
    <li><b>Инновации в маркетинге:</b> Вручается за разработку и успешное применение прорывных инновационных методов в маркетинге, которые изменили или существенно повлияли на практики в отрасли.</li>
      <li><b>Лидерство в брендинге:</b> Присуждается лидеру или команде, стоящей за созданием или реинвентаризацией бренда, который оказал существенное влияние на рынок и потребительское восприятие.</li>
      <li><b>Социальный и этичный маркетинг:</b> Награда для тех, кто успешно внедрил маркетинговые стратегии, способствующие решению социальных проблем, улучшению качества жизни и соблюдению высоких этических стандартов в бизнесе.</li>
      <li><b>Международный маркетинговый вклад:</b> Подтверждает значительный вклад индивида или организации в расширение и развитие международных маркетинговых стратегий, укрепление маркетинговых связей и продвижение продуктов на глобальном уровне.</li>
      <li><b>Образование и наставничество в маркетинге:</b> Признает вклад в развитие маркетингового образования, включая учебные программы, научные исследования и публикации, а также усилия по наставничеству и развитию следующего поколения маркетологов.</li>
      </ol>
      <div className="p-60">
      <p className="is-italic has-text-right is-underlined">УТВЕРЖДЕНО</p>
      <p className="has-text-right">Советом Некоммерческого Партнерства</p>
      <p className="is-italic has-text-right">«МЕЖДУНАРОДНАЯ АССОЦИАЦИЯ МАРКЕТОЛОГОВ СНГ»</p>
      <p className="has-text-right">«18» декабря 2018 г.</p>
      <p className="has-text-right">(Протокол заседания Совета
        Международной Ассоциации Маркетологов СНГ № 45-2018 от 18.12.2018 г.)</p>
        </div>
        <div className="title is-font-title-regular has-text-center has-text-var-blue p-60">СТАТУТ</div>
        <p className="has-text-centered">Отличительного орденского знака</p>
        <p className="has-text-centered is-italic">«За заслуги в маркетинге»</p>
        <p className="has-text-centered">Международной Ассоциации Маркетологов СНГ</p>
    <p>1. Отличительный орденский знак «За заслуги в маркетинге» является наградой Некоммерческого Партнерства «Международная Ассоциация Маркетологов СНГ».</p>
    <p>2. Отличительным орденским знаком «За заслуги в маркетинге» награждаются за:</p>
      <ul className="criteriaList">
    <li>высокий уровень профессионализма в сфере маркетинга;</li>
      <li>наличие активной и эффективной деятельности в области распространения и популяризации маркетинга в отдельных странах, регионах или в мире — в целом;</li>
      <li>наличие признанных выдающихся заслуг в области теории и практики развития маркетинга как научного направления или как практических итогов отдельных коммерческих и общественных проектов;</li>
      <li>большой, признанный личный вклад в развитие социально-ориентированной рыночной экономики.</li>
      </ul>
      <p>3. Награждение отличительным орденским знаком «За заслуги в маркетинге» происходит в следующих областях:</p>
      <ol className="criteriaList">
      <li>Инновации в маркетинге</li>
      <li>Лидерство в брендинге</li>
      <li>Социальный и этичный маркетинг</li>
      <li>Международный маркетинговый вклад</li>
      <li>Образование и наставничество в маркетинге</li>
      </ol>
      <p>4. Отличительный орденский знак «За заслуги в маркетинге» выдаётся навечно, без права изъятия.</p>
      <p>5. Инициатива выдвижения кандидатур для награждения отличительным орденским знаком «За заслуги в маркетинге» принадлежит Кавалерам Орденского знака — действительным и почетным членам Международной Ассоциации Маркетологов СНГ. Для того чтобы кандидатура была принята к рассмотрению Комитетом по Званиям и Наградам, достаточно, чтобы в ее пользу высказались ДВА рекомендателя. Инициаторы выдвижения должны представить Комитету по Званиям формальное письменное обоснование в пользу выдвигаемой кандидатуры.</p>
      <p>6. Из предложенных действительными членами Ассоциации кандидатур Комитет по Званиям и Наградам формирует список из трех кандидатур в каждой из номинаций.</p>
      <p>7. Окончательное решение по кандидатуре награждаемого отличительным орденским знаком «За заслуги в маркетинге» принимается на заседании Совета Международной Ассоциации Маркетологов СНГ.</p>
      <p>8. Награждение отличительным орденским знаком «За заслуги в маркетинге» подтверждается именным номерным Свидетельством о награждении, на русском языке, удостоверенным печатью Некоммерческого Партнерства  «Международной Ассоциации Маркетологов СНГ» и подписью Президента Ассоциации Маркетологов.</p>
      <p>9. Отличительный орденский знак «За заслуги в маркетинге» носится на левой стороне груди, прикреплённым к одежде, не принижающей достоинства награды и имиджа Ассоциации Маркетологов.</p>
      <p>10. Отличительный орденский знак «За заслуги в маркетинге» содержит в себе зарегистрированный логотип Некоммерческого Партнерства «Международной Ассоциации Маркетологов СНГ», являющийся собственностью НП « Международной Ассоциации Маркетологов СНГ», охраняемой Законом Российской Федерации.</p>
      <p>11. Отличительный орденский знак «За заслуги в маркетинге» производится и дублируется только с разрешения Совета Ассоциации Маркетологов, проведённого в соответствии с Уставом, Положениями и Правилами об организации заседания Совета.</p>
      <p>12. Отличительный орденский знак «За заслуги в маркетинге» даёт награждённому, вне зависимости от принадлежности к Ассоциации Маркетологов, право:</p>
      <ul className="criteriaList">
    <li>на вступление в члены Международной Ассоциации Маркетологов СНГ по изъявлению желания,</li>
      <li>на освобождение от уплаты вступительного взноса,</li>
      <li>на участие, без специального приглашения, во всех открытых и бесплатных мероприятиях Международной Ассоциации Маркетологов СНГ,</li>
      <li>на размещение информации в электронных и печатных СМИ Международной Ассоциации Маркетологов СНГ наряду с действительными членами Международной Ассоциации Маркетологов СНГ.</li>
      </ul>
      </div>
    </div>
  );
}

export default Order;
