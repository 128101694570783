

function ArticlePage02() {

    return (
      <div className="xContent p-box py-6">
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Как правильно выбрать вуз</h1>
        <div className="content wysiwyg">
        <p className='has-text-centered p-style p-1'>КАК ПРАВИЛЬНО ВЫБРАТЬ ВУЗ?</p>
        <p>Для этого надо на время стать самому маркетологом.</p>
        <p>Шучу, давайте посмотрим, как это может сделать профессиональный маркетолог.</p>
        <p>Предположим, есть два классических варианта выбора.</p>
        <p>1. Эмоциональный. По престижу, известности. Учатся друзья. Вуз хвалят. Спорт, студенческая жизнь  (КВН и пр.)</p>
        <p>2. Рациональный. Не дорого. Хорошие педагоги. Есть иностранные языки. Недалеко от дома. Стажировки, трудоустройство.</p>
        <p>— Пункт 1 и 2 – это бренд вуза. Все вместе, это любовь, ваш выбор уже сделан в голове.</p>
        <p>Но, если вы еще в раздумье, то далее этот текст для вас.</p>
        <p>Заметим, работодатели особенно не обращают внимание на бренд вуза, их больше интересует, а какие скилы есть у соискателя!</p>
        <p>Какие же вузы дают эти скилы?</p>
        <p>Как же выбрать правильный надежный вуз, где научат, как стать маркетологом, рекламистом, PR и журналистом?</p>
        <p>Как не влипнуть и не потратить деньги зря?</p>
        <p>Пошли далее советы и рекомендации!</p>
        <p>— Выбирайте вуз, как если бы, вы выбираете товары длительного пользования: квартиру, дачу, машину.</p>
        <p>Посмотрите на  «фундамент» дома — государственный или нет, „мотор“ — качество образовательной программы.</p>
        <p>— Для таких специальностей как: маркетинг, реклама и пиар, журналистика лучше подходят гуманитарные вузы или почти гуманитарные, социально-экономические.</p>
        <p>Эти профессии требуют от человека высокой коммуникативности и творчества. Умение коммуницировать требует изучения истории, культуры, философии, социологии, лингвистики.</p>
        <p>— Обратите внимание на наличие в этих вузах факультетов или кафедр в области управления, экономики, социологии, юриспруденции. Если они есть, то школа и педагогическая, и научная здесь обеспечена.</p>
        <p>Вас там, как минимум, не испортят.</p>
        <p>Есть вузы, где процесс образования напоминает длительные многогодичные курсы. Они, в силу их  «молодости» не имеют богатой материально-технической базы и качественных преподавателей.</p>
        <p>Это не ваши вузы.</p>
        <p>— Имейте ввиду, что на подготовку к конкретной профессии, чтения лекций и семинаров в качественных вузах, чтобы подготовить специалиста в области менеджмента рекламы, специалиста по PR, интернет маркетолога, SMM менеджера, копирайтера, веб-дизайнера, контент – менеджера, тем более SEO специалиста или веб-аналитика приглашаются специалисты — практики в этих профессиональных областях. Смотрите, есть такие в составе факультета, кафедр.</p>
        <p>— Для таких специальностей как: маркетинг, реклама и пиар, журналистика лучше подходят гуманитарные вузы или почти гуманитарные, социально-экономические.</p>
        <p>Эти профессии требуют от человека высокой коммуникативности и творчества. Умение коммуницировать требует изучения истории, культуры, философии, социологии, лингвистики.</p>
        <p>Как можно понять, есть ли устойчивая корреляция между брендом (виртуальным качеством) и реальным качеством обучения в вузе?</p>
        <p>Маркетологи, обычно, прежде чем рекомендовать тот или иной путь к успеху, проводят ситуационный анализ, оперативное исследование рынка.</p>
        <p>Для такого экспресс исследования были выбраны 8 вузов, где можно получить высшее образование по указанным направлениям, где набор ЕГЭ примерно совпадает.</p>
        <p>Это: ВШЭ, РУДН, ФУ, РГГУ, РЭУ, МосПолитех, ГУУ, РГСУ.</p>
        <p>— 1). Бренд вуза — это широкая и безусловная известность среди выпускников, абитуриентов и их родителей.</p>
        <p>Не надо рассказывать никому, что такое МВТУ, МИФИ, МФТУ. Все и так знают, это интеллектуальные монстры с техническим и математическим уклоном. Сюда же надо добавить немного более социальные вузы, но такие же престижные: МГИМО, ВШЭ. Это мощные, раскрученные, широко известные торговые марки вузов -бренды России. Про МГУ речь не веду, сам его окончил пару раз, вы все сами знаете, что здесь классика обучения.</p>
        <p>— Но нас интересуют вузы, где учат маркетологов, рекламщиков, PR-щиков и журналистов.</p>
        <p>Общественное мнение, рейтинг этих брендов, спрос на такие вузы со стороны потребителей высшего образования может быть зафиксирована по рейтингу запросам в Яндекс.</p>
        <p>На основании собранной там информации (она доступна всем, каждый может это проверить), выясняется:</p>
        <p>— Самый высокий спрос: собирает ВШЭ – 9 804 запроса в месяц. Это огромным отрыв и успех – общественное признание среди всех остальных вузов.</p>
        <p>Далее идут вузы по убыванию запросов: РУДН (983); ФУ (818); РГГУ (626); РЭУ (402); МосПолитех (395); ГУУ (213); РГСУ (178).</p>
        <p>Так, теперь мы видим, как выстроились бренды вузов.</p>
        <p>Вроде понятно, куда идти учиться!</p>
        <p>Но?</p>
        <p>— А как определить качественные вузы? Как измерить качество? По сути, 100%, никак. Но, все же можно. Это можно сделать только со стороны экспертов. Нет, не нами, не преподавателями – заинтересованными лицами, не на ДОД), но по мнению студентов и выпускников.</p>
        <p>Правильно будет получить оценку от тех, кто является (являлся) субъектами образовательной деятельности, непосредственными участниками образовательного процесса, кто конкретно натерпелся и крикнул, когда уходил, ура, образование закончилось!</p>
        <p>Вы можете возразить, а что их спрашивать, они еще молодые и ничего не …).</p>
        <p>Да, они молодые, но больше не у кого спрашивать. Кстати, спрашивают анонимно и по максимуму многих.</p>
        <p>По результатам опроса строится рейтинг реального качества вуза.</p>
        <p>— Вы будете удивлены, но общественное мнение (имидж) не совпадает с частным внутренним мнением экспертов (репутацией), со стороны самих участников обучения.</p>
        <p>— На поверку, вузы становятся не вполне брендовыми, т.е. качество их обучения ниже их всенародной, исторической славы!</p>
        <p>И они тут выстраиваются, наоборот.</p>
        <p>— С большим отрывом, по набранным баллам, от других вузов заметны два качественных вуза: ГУУ (85,3) и РГСУ (84,3).</p>
        <p>Далее идут ВШЭ (84.0), РГГУ (83,5).</p>
        <p>Потом следуют, по убыванию: Фин. Универ (83,4), Московский Политех (82,5), РУДН (78,0). Замыкает эту спускающуюся вниз колонну – РЭУ (76,5)!</p>
        <p>Это рейтинг реального качества обучения, который составлен на основании экспертного заключения, набранным баллам со стороны студентов и выпускников.</p>
        <p>Не стоит приводить примеры отзывов, сами их посмотрите. Они разные, от хороших, до очень даже негативных.</p>
        <p>Так решили оценить качество обучения в этих вузах студенты, которые там учатся.</p>
        <p>Я же, просто подсмотрел.</p>
        <p>— Так кому же верить, как же так, скажите вы, товарищ, профессор, вы что нам голову морочите?</p>
        <p>А тусовка, а пальцы гнуть, а где лучше пыльнуть, а …)?</p>
        <p>— Решать, только вам!</p>
        <p>Выбор профессии и вуза – это выбор будущей и настоящей профессиональной и личной биографии.</p>
        <p>Многие, до 70% выпускников продолжают свое образование в одном и том же вузе. Получают еще одно образование, второе, магистратуру, аспирантуру.</p>
        <p>У студентов есть шанс поменять свою профессию во время учебы, перейти на другую программу обучения.</p>
        <p>Сегодня с помощью Интернет, все мы превращаемся в  «вечных студентов».</p>
        <p>Это уже устойчивый тренд нашего времени.</p>
        <p>Какой из этого можно сделать вывод?</p>
        <p>— При выборе вуза, конечно, маркетологом вы не станете и не сможете провести тщательный анализ реального качества брендов!</p>
        <p>Мы, маркетологи сделаем это за вас!</p>
        <p>Подобный анализ может быть проведен по любым другим вузам и профессиям, которые вы пытаетесь выбирать.</p>
        <p>Обращайтесь в Ассоциацию Маркетологов.</p>
        </div>
      </div>
    );
  }

  export default ArticlePage02;
