import React from 'react';
import LogoImg from '../images/logo.svg'

function Header() {
  return (
    <div className="xHeader">
        <div className="columns is-vcentered is-mobile is-centered">
            <div className="column">
                <div className="xHeaderTitle has-text-right">
                    «Международная Ассоциация<br/> маркетологов СНГ»
                    <div>некоммерческое партнёрство</div>
                </div>
            </div>
            <div className="column has-text-center is-narrow">
                <a href="/" class="is-block">
                    <img src={LogoImg} alt="logo"/>
                </a>
            </div>
            <div className="column">
                <div class="xHeaderTitle has-text-left">
                «Мы формируем будущее <br/>маркетинга сегодня»
                </div>
            </div>
        </div>
    </div>
  );
}

export default Header;
