import React from 'react';
import { Helmet } from 'react-helmet-async';

function AboutUs() {
  return (
    <div className="xContent p-box py-6">
      <Helmet>
        <title>О нас</title>
        <meta
        name="description"
        content="Международная Ассоциация Маркетологов СНГ является престижной профессиональной организацией, объединяющей ведущих специалистов в области маркетинга"
        />
        <link rel="canonical" href="https://marketing-association.com/about-us" />
      </Helmet>
      <h1 className="title">О нас</h1>
      <div className="content p-20">
      <p>Международная Ассоциация Маркетологов СНГ является престижной профессиональной организацией, объединяющей ведущих специалистов
        в области маркетинга из России, Украины, Азербайджана,
        Армении, Беларуси, Казахстана, Киргизии, Молдовы, Таджикистана, Туркменистана и Узбекистана.
        Членство в ассоциации ограничено и доступно только для тех маркетологов, которые достигли выдающихся успехов в своей профессиональной деятельности.</p>
        <p>Кандидаты для вступления в ассоциацию должны соответствовать ряду строгих критериев, в том числе:</p>
        <ul className="criteriaList">
        <li>Достижение высокого уровня в области маркетинговых технологий, что подтверждается наличием Национальных и Международных наград в области маркетинга.</li>
        <li>Обладание значительным профессиональным опытом, включая не менее трех лет руководящей работы, что свидетельствует о высокой квалификации и управленческих навыках.</li>
        <li>Активное участие в развитии профессионального сообщества, включая проведение обучающих мастер-классов и семинаров, направленных на повышение квалификации специалистов в области маркетинга.</li>
        <li>Наличие публикаций в рейтинговых журналах, что подтверждает их вклад в развитие маркетинговых технологий.</li>
      </ul>
    <p>Международная Ассоциация Маркетологов СНГ не только служит платформой для обмена знаниями и опытом среди
        лидеров отрасли, но и играет важную роль в формировании стандартов маркетинговой деятельности, продвигает инновации и лучшие практики в маркетинге на рынках СНГ. Ассоциация также вносит значительный
        вклад в развитие профессионального сообщества маркетологов, поддерживая исследования и образовательные проекты, организуя профильные мероприятия и конференции, а также содействуя международному сотрудничеству в сфере маркетинга.</p>
        <p>Цели Международной Ассоциации Маркетологов СНГ:</p>
        <ol className="goalsList">
        <li>Содействие развитию маркетинговой отрасли: Поддержка инициатив, направленных на развитие и совершенствование маркетинговой отрасли в странах СНГ.</li>
        <li>Обмен знаниями и лучшими практиками: Создание платформы для обмена опытом, знаниями и лучшими практиками между маркетологами стран-участниц.</li>
        <li>Профессиональное образование и повышение квалификации: Организация обучающих программ, семинаров и мастер-классов для маркетологов с целью повышения их профессиональных компетенций.</li>
        <li>Стандартизация маркетинговых подходов: Разработка и внедрение профессиональных стандартов и этических норм в маркетинговой деятельности.</li>
        <li>Поддержка инноваций: Поощрение инноваций и внедрение новейших технологий в маркетинговые стратегии и практики.</li>
        <li>Развитие международного сотрудничества: Формирование и развитие партнерских отношений между маркетологами стран СНГ и международным маркетинговым сообществом.</li>
        <li>Повышение эффективности маркетинговой деятельности: Анализ рынков и изучение потребительского поведения для улучшения эффективности маркетинговых кампаний.</li>
        <li>Защита интересов маркетологов: Представление и защита профессиональных интересов маркетологов на государственном и международном уровнях.</li>
        <li>Создание профессионального сообщества: Поддержание и развитие профессионального сообщества маркетологов для установления деловых контактов и сетевого взаимодействия.</li>
        <li>Публикация исследований: Поддержка проведения научных исследований в области маркетинга и публикация результатов в рейтинговых журналах для повышения уровня знаний в отрасли.</li>
      </ol>
    </div>
    </div>
  );
}

export default AboutUs;
