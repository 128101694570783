import React from 'react';
import News from './News';
import OrderImg from '../images/order.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function MainContent() {
  return (
    <div className='xContent py-6'>
    <div className="xContent p-box ">
        <Helmet>
        <title>Список членов Ассоциации</title>
        <meta property="og:type" content="website"/>
    <meta property="og:title" content="Некоммерческое партнерство «Международная Ассоциация маркетологов СНГ»"/>
    <meta property="og:description" content="Международная Ассоциация Маркетологов СНГ является престижной профессиональной организацией, объединяющей ведущих специалистов
    в области маркетинга из России, Украины, Азербайджана и других"/>
    <meta property="og:url" content="https://marketing-association.com/"/>
    <meta property="og:image" content="../../public/preview.png"/>
    <link rel="icon" href="../../public/logo.svg" />
    <link rel="shortcut icon" href="../../public/logo.svg" />
        <meta
        name="description"
        content="Международная Ассоциация Маркетологов СНГ является престижной профессиональной организацией, объединяющей ведущих специалистов
        в области маркетинга из России, Украины, Азербайджана и других"
        />

        <link rel="canonical" href="https://marketing-association.com/" />
      </Helmet>
            <h1 className="title">О Международной Ассоциации маркетологов СНГ</h1>
      <div className="content p-20">
      <p>Международная Ассоциация Маркетологов СНГ является престижной профессиональной организацией, объединяющей ведущих специалистов
        в области маркетинга из России, Украины, Азербайджана,
        Армении, Беларуси, Казахстана, Киргизии, Молдовы, Таджикистана, Туркменистана и Узбекистана.
        Членство в ассоциации ограничено и доступно только для тех маркетологов, которые достигли выдающихся успехов в своей профессиональной деятельности.</p>
        <p className="p-1">Международная Ассоциация Маркетологов СНГ не только служит платформой для обмена знаниями и опытом среди
        лидеров отрасли, но и играет важную роль в формировании стандартов маркетинговой деятельности, продвигает инновации и лучшие практики в маркетинге на рынках СНГ. Ассоциация также вносит значительный
        вклад в развитие профессионального сообщества маркетологов, поддерживая исследования и образовательные проекты, организуя профильные мероприятия и конференции, а также содействуя международному сотрудничеству в сфере маркетинга.</p>
        <div className="block button-content has-text-centered">
    <Link to="/about-us" className="is-more-w"><span>Больше о нас</span></Link>
    </div>
      </div>
      <News />
    </div>
    <div className='main-card flex-container'>
        <div>
        <div className='title has-text-centered'>ОРДЕН «ЗА ЗАСЛУГИ В МАРКЕТИНГЕ»</div>
        <div className='main-card-text has-text-centered' >Орден "За заслуги в маркетинге" вручается выдающимся маркетологам за личные высокие достижения, которые оказали значительное влияние на рост и развитие маркетинговой отрасли в своих странах и за её пределами.</div>
        <div className="block button-content">
        <Link to={{
          pathname: "/order",
          state: { scrollToTop: true }
        }} className="is-more-w">
          <span>Перейти</span>
        </Link>
        </div>
        </div>
        <div className='card-img'><img src={OrderImg} alt="Order" /></div>
      </div>
    </div>
  );
}

export default MainContent;
