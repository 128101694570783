import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

function Statute() {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);
    const section8Ref = useRef(null);
    const section9Ref = useRef(null);
    const section10Ref = useRef(null);
    const section11Ref = useRef(null);
    const section12Ref = useRef(null);
    const section13Ref = useRef(null);
    const section14Ref = useRef(null);
    const section15Ref = useRef(null);
    const section16Ref = useRef(null);
    const section17Ref = useRef(null);

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };


    return (
      <div className="xContent p-box py-6">
        <Helmet>
        <title>Устав</title>
        <meta
        name="description"
        content='Устав Международной Ассоциации Маркетологов СНГ'
        />
        <link rel="canonical" href="https://marketing-association.com/statute"/>
      </Helmet>
        <div className="title is-font-title-regular has-text-centered has-text-var-blue">
        УСТАВ Международной Ассоциации Маркетологов
        </div>
        <div className="content p-20 wysiwyg">
      <p className="is-italic has-text-left">Утверждён Собранием Членов Правления</p>
      <p className="has-text-left">Международной Ассоциации Маркетологов СНГ</p>
      <p className="has-text-left">15 января 2018 года Протокол № 1</p>


        <h2 className="title is-font-title-regular has-text-centered has-text-var-blue">УСТАВ</h2>
        <p className="has-text-centered">Некоммерческого партнерства</p>
        <p className="has-text-centered">«Международная Ассоциация Маркетологов СНГ»</p>
        <p className="has-text-centered is-italic">г. Москва, 2018 год</p>


      <section className="tableOfContents">
        <p className="has-text-centered fs-16"><b>Содержание:</b></p>
        <ul>
          <li onClick={() => scrollToSection(section1Ref)}><div className="li_style">Общие положения</div></li>
          <li onClick={() => scrollToSection(section2Ref)}><div className="li_style">Цели и предмет деятельности</div></li>
          <li onClick={() => scrollToSection(section3Ref)}><div className="li_style">Члены Ассоциации</div></li>
          <li onClick={() => scrollToSection(section4Ref)}><div className="li_style">Права и обязанности членов Ассоциации</div></li>
          <li onClick={() => scrollToSection(section5Ref)}><div className="li_style">Порядок вступления в члены Ассоциации</div></li>
          <li onClick={() => scrollToSection(section6Ref)}><div className="li_style">Порядок выхода из членов Ассоциации</div></li>
          <li onClick={() => scrollToSection(section7Ref)}><div className="li_style">Членские взносы и другие платежи</div></li>
          <li onClick={() => scrollToSection(section8Ref)}><div className="li_style">Права Ассоциации</div></li>
          <li onClick={() => scrollToSection(section9Ref)}><div className="li_style">Имущество и средства Ассоциации</div></li>
          <li onClick={() => scrollToSection(section10Ref)}><div className="li_style">Управление</div></li>
          <li onClick={() => scrollToSection(section11Ref)}><div className="li_style">Собрание членов Ассоциации</div></li>
          <li onClick={() => scrollToSection(section12Ref)}><div className="li_style">Совет Ассоциации</div></li>
          <li onClick={() => scrollToSection(section13Ref)}><div className="li_style">Президент</div></li>
          <li onClick={() => scrollToSection(section14Ref)}><div className="li_style">Ревизионная комиссия</div></li>
          <li onClick={() => scrollToSection(section15Ref)}><div className="li_style">Учет и отчетность</div></li>
          <li onClick={() => scrollToSection(section16Ref)}><div className="li_style">Архив, работа с документами</div></li>
          <li onClick={() => scrollToSection(section17Ref)}><div className="li_style">Реорганизация и ликвидация</div></li>
        </ul>
      </section>
      <section id="1" ref={section1Ref}>
        <div className="title is-font-title-regular has-text-left has-text-var-blue pt-5">1. Общие положения</div>
        <p>1.1. <b>Некоммерческое партнерство «Международная Ассоциация Маркетологов СНГ»</b>, далее по тексту именуемое «Ассоциация», является основанной на членстве некоммерческой организацией, учрежденной гражданами таких стран как Россия, Украина, Азербайджан, Армения, Беларусь, Казахстан, Киргизия, Молдова, Таджикистан, Туркменистан, Узбекистан, Грузия и юридическим лицом, далее именуемыми «Члены», для содействия им в осуществлении деятельности, направленной на достижение целей, предусмотренных настоящим Уставом, в соответствии с Законом Российской Федерации „О некоммерческих организациях“ от 12 января 1996 года № 7-ФЗ.</p>
        <p>1.2. Ассоциация действует на основании законодательства Российской Федерации, а также законодательства стран места пребывания филиалов и представительств Ассоциации, международных договоров и иных нормативных актов международного сотрудничества. Уставную деятельность Ассоциации регламентирует также настоящий Устав.</p>
        <p>1.3. Наименование Ассоциация:
            полное наименование на русском языке: <b>Некоммерческое партнерство «Международная Ассоциация Маркетологов СНГ» </b>;
            полное наименование на английском языке: <b>«Non-profit Partnership International Association of Marketers of the CIS».</b></p>
        <p>1.4. Место нахождения Ассоциации: 125009, г. Москва, Газетный переулок, д. 9, корпус 4, офис 305
            Место нахождения Ассоциации определяется местом нахождения его исполнительного органа.
            Почтовый адрес Ассоциации: 127018, г. Москва, а/я 133</p>
        <p>1.5. Ассоциация считается созданной как юридическое лицо с момента ее государственной регистрации в установленном законом порядке, имеет в собственности или в оперативном управлении обособленное имущество, отвечает по своим обязательствам этим имуществом, может от своего имени приобретать и осуществлять имущественные и неимущественные права, нести обязанности, быть истцом и ответчиком в суде.</p>
        <p>1.6. Ассоциация имеет самостоятельный баланс.</p>
        <p>1.7. Государственная регистрация изменений учредительных документов Ассоциации осуществляется в порядке, установленном законом о государственной регистрации юридических лиц.
            Изменения учредительных документов Ассоциации вступают в силу с момента их государственной регистрации.</p>
        <p>1.8. Ассоциация создается без ограничения срока деятельности.</p>
        <p>1.9. Ассоциация вправе в установленном порядке открывать счета в банках на территории Российской Федерации и за пределами ее территории.</p>
        <p>1.10. Ассоциация имеет печать со своим полным наименованием на русском языке. Ассоциация вправе иметь штампы и бланки со своим наименованием, а также зарегистрированную в установленном порядке эмблему.</p>
        <p>1.11. Ассоциация отвечает по своим обязательствам в пределах принадлежащего ему имущества, на которое может быть обращено взыскание в соответствии с законодательством. Государство не отвечает по обязательствам Ассоциация, равно как и Ассоциация не отвечает по обязательствам государства. Имущество, переданное Ассоциация его Членами, является собственностью Ассоциации. Члены Ассоциации не отвечают по его обязательствам, а Ассоциация не отвечает по обязательствам своих Членов.</p>
        <p>1.12. Ассоциация вправе осуществлять предпринимательскую деятельность, соответствующую целям, для достижения которых оно создано.</p>
        <p>1.13. Ассоциация не имеет извлечение прибыли в качестве основной цели своей деятельности и не распределяет полученную прибыль между своими Членами.</p>
        <p>1.14. Ассоциация выполняет государственные мероприятия по мобилизационной подготовке в соответствии с действующим законодательством и нормативными актами правительства г. Москвы.</p>
        <p>1.15. Ассоциация в процессе своей деятельности ведет работу по учету и бронированию граждан, находящихся в запасе, и призывников в соответствии с требованиями законодательства Российской Федерации и постановлениями Правительства Российской Федерации. Персональная ответственность за выполнение этой работы возлагается на Президента Ассоциации.</p>
      </section>
      <section id="2" ref={section2Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">2. Цели и предмет деятельности</div>
        <p>2.1. Целью создания и деятельности Ассоциации является содействие развитию социально-ориентированной рыночной экономики, развитию маркетинга в таких странах, как Россия, Украина, Азербайджан, Армения, Беларусь, Казахстан, Киргизия, Молдова, Таджикистан, Туркменистан, Узбекистан, Грузия, личному профессиональному росту Членов Ассоциации, осуществляемое посредством совместных действий в сферах науки, практики управления, консультирования и образования, обмена информацией, взаимовыгодного сотрудничества и внедрения современных социально-этичных методов налаживания и осуществления партнерства, а также ведения конкурентной борьбы, отвечающих высоким профессиональным стандартам.</p>
        <p>2.2. Ассоциации ставит перед собой следующие задачи:</p>
        <p>2.2.1. Содействие и создание условий для регулярного профессионального общения, обмена информацией, знаниями, опытом и мнениями между руководителями служб и отделов маркетинга, независимыми консультантами, исследователями рынка, преподавателями и авторами книг по маркетингу.</p>
        <p>2.2.2. Установление и поддержание высоких профессиональных стандартов, включая выработку объективных критериев оценки работы специалистов и установление справедливых расценок.</p>
        <p>2.2.3. Содействие установлению большего взаимопонимания, партнерству, урегулированию споров и конфликтов между участниками рынка.</p>
        <p>2.2.4. Совместная реализация социально-значимых практических, научных и учебных проектов.</p>
        <p>2.2.5. Взаимовыгодный обмен заказами и субподрядами.</p>
        <p>2.2.6. Пропаганда современного маркетинга долгосрочных взаимоотношений в качестве неотъемлемого элемента стратегии управления современными предприятиями и организациями.</p>
        <p>2.2.7. Представление и защита профессиональных интересов маркетологов и их сообщества в средствах массовой информации и сферах власти.</p>
        <p>2.2.8. Налаживание эффективных взаимоотношений с органами государственной власти и местного самоуправления по вопросам, затрагивающим интересы маркетологов и иных участников рынка маркетинговых услуг;</p>
        <p>2.2.9. Повышение самодисциплины членов Партнерства, исследование фактов нарушения установленных стандартов и правил и арбитраж в разрешении споров между членами Партнерства, между членами Партнерства и третьими лицами;</p>
        <p>2.2.10. Информационная, методическая, техническая и правовая поддержка членов Партнерства.</p>
        <p>2.3. Предметом деятельности Ассоциации является деятельность по анализу, планированию, осуществлению и контролю оказания маркетинговых услуг. Для реализации своих целей и задач Ассоциация:</p>
        <p>2.3.1. Разрабатывает принципы и правила оказания маркетинговых услуг Членами Ассоциации, соответствующие стандарты поведения, другие нормы, обязательные для исполнения Членами Ассоциации;</p>
        <p>2.3.3. Разрабатывает и осуществляет процедуру проведения и ознакомления с результатами проверок на предмет соблюдения Членами Ассоциации установленных правил и стандартов, создает контрольный орган Партнерства;</p>
        <p>2.3.4. Устанавливает рекомендуемый порядок оформления и учета документов, используемых членами Ассоциации при оказании маркетинговых услуг;</p>
        <p>2.3.5. Разрабатывает правила, ограничивающие манипулирование рынком при оказании маркетинговых услуг членами Ассоциации;</p>
        <p>2.3.4. Создает информационные базы данных по вопросам оказания маркетинговых услуг;</p>
        <p>2.3.7. Разрабатывает, создает и владеет информационными, коммуникационными, торговыми и иными системами, непосредственно способствующими оказанию маркетинговых услуг. Разрабатывает стандарты информационного обмена между членами Ассоциации, а также между членами Ассоциации и третьими лицами;</p>
        <p>2.3.8. Разрабатывает и применяет к членам Ассоциации санкции и меры за нарушение установленных стандартов и правил;</p>
        <p>2.3.9. Выступает третейским арбитром при разрешении споров между членами Ассоциации, между членами Ассоциации и третьими лицами;</p>
        <p>2.3.10. Разрабатывает правила и осуществляет действия по раскрытию информации в соответствии с законодательством РФ;</p>
        <p>2.3.11. Разрабатывает порядок предоставления информации Ассоциации, членами Ассоциации;</p>
        <p>2.3.12. Готовит и выпускает учебно-методические пособия, рекламные проспекты, различные виды научно-технической документации, организует публикацию научных трудов, лекций, а также обучение по различным аспектам своей деятельности.</p>
        <p>2.4. Ассоциация вправе осуществлять предпринимательскую деятельность, соответствующую целям, для достижения которых она создана. Такой деятельностью является приносящее прибыль производство товаров и услуг, отвечающих целям создания Ассоциации, а так же приобретение и реализация ценных бумаг, имущественных и неимущественных прав, участие в хозяйственных обществах и участие в товариществах на вере в качестве вкладчика.</p>
        <p>2.5. Ассоциация вправе осуществлять так же любую другую деятельность, не запрещенную законодательством РФ.</p>
      </section>
      <section id="3" ref={section3Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">3. Члены Ассоциации</div>
      <p>3.1. Членами Ассоциации могут быть граждане таких стран, как Россия, Украина, Азербайджан, Армения, Беларусь, Казахстан, Киргизия, Молдова, Таджикистан, Туркменистан, Узбекистан, Грузия, объединившиеся для реализации уставных целей Ассоциации, признающие Устав Ассоциации, внесшие членские вступительные взносы и принятые в состав Партнерства в порядке, установленном настоящим Уставом.</p>
      <p>3.2. Число Членов Ассоциации не ограничено. Некоммерческое Партнерство не может быть учреждено одним лицом.</p>
      </section>
      <section id="4" ref={section4Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">4. Права и обязанности членов Ассоциации.</div>
      <p>4.1. Члены Ассоциации вправе:
        <br />— участвовать в управлении делами Ассоциации;
        <br />— получать информацию о деятельности Ассоциации в установленном учредительными документами порядке;
        <br />— по своему усмотрению выходить из Ассоциации;
        <br />— получать при выходе из Ассоциации часть ее имущества или стоимость этого имущества в пределах стоимости имущества, переданного Членами Ассоциации в ее собственность, за исключением членских взносов, в порядке, предусмотренном учредительными документами;
        <br />— получать в случае ликвидации Ассоциации часть ее имущества, оставшегося после расчетов с кредиторами, либо стоимость этого имущества в пределах стоимости имущества, переданного Членами Ассоциации в ее собственность.</p>
      <p>4.2. Члены Ассоциации обязаны:
        <br />— соблюдать положения и выполнять обязательства, предусмотренные учредительными документами и решениями Собрания Членов Ассоциации, выполнять принятые на себя обязательства перед Ассоциацией;
        <br />— воздерживаться от всякой деятельности, которая может нанести ущерб Ассоциации и ее Членам;
        <br />— своевременно вносить вступительные, периодические и иные целевые взносы;
        <br />— принимать участие в финансировании деятельности, в том числе для создания собственных целевых фондов Ассоциации для финансирования мероприятий, вытекающих из целей и задач Ассоциации;
        <br />— незамедлительно сообщать руководящим органам Ассоциации о реорганизации или ликвидации, изменениях формы собственности, местонахождения и номеров телефонов (факсов), об изменениях в составе руководства юридических лиц — Членов Ассоциации; об изменениях почтового адреса и номеров телефонов, иных данных, связанных с членством в Ассоциации, физических лиц;
        <br />— предоставлять справочно-информационные данные, не относящиеся к категории коммерческой тайны;
        <br />— не разглашать конфиденциальную информацию и коммерческую тайну о деятельности Ассоциации;
        <br />— развивать сотрудничество и взаимную поддержку Членов Ассоциации.</p>
      </section>
      <section id="5" ref={section5Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">5. Порядок вступления в члены Ассоциации.</div>
      <p>5.1. В Ассоциацию могут быть приняты только выдающиеся маркетологи, которые получили национальное или международное признание и  соответствуют следующим критериям:</p>
      <ul className='criteriaList'>
      <li className='fs-24'>Кандидат имеет на своем счету Национальные или Международные награды, премии или другие виды признания, подтверждающие его достижения в области маркетинга.</li>
      <li className='fs-24'>Статьи о выдающейся деятельности кандидата в области маркетинга, опубликованные в крупных или известных изданиях, либо научные статьи, написанные кандидатом и опубликованные в профессиональных или крупных изданиях.</li>
      <li className='fs-24'>Подтверждение проведения как минимум 2-х мастер классов по маркетингу на Национальных или Международных конференциях или форумах.</li>
      <li className='fs-24'>Подтверждение опыта работы на руководящей должности не менее 2-х лет.</li>
      </ul>
      <p>5.2. Решение о приеме в члены Ассоциации принимается Советом Правления на Заседании Правления Ассоциации, что фиксируется в Протоколе Заседания.</p>
      <p>5.3. Кандидат получает Свидетельство Члена Ассоциации, подписанное Председателем Ассоциации, а его фамилия заносится на официальный сайт Ассоциации в раздел «Список членов Ассоциации»: <a href="https://marketing-association.com/members">https://marketing-association.com/members</a></p>
      </section>
      <section id="6" ref={section6Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">6. Порядок выхода из членов Ассоциации.</div>
      <p>6.1. Член Ассоциации вправе по своему усмотрению выйти из ее состава в сроки и на условиях, определенных Советом Ассоциации.</p>
      <p>6.2. Для выхода из Ассоциации необходимо направить заявление Совету Ассоциации не позднее, чем за 1 (один) месяц до окончания срока Договора, заключенного с Членом Ассоциации.</p>
      <p>6.3. Совет Ассоциации обязан письменно подтвердить факт и дату выхода Члена из Ассоциации.</p>
      <p>6.4. Член Ассоциации может быть исключен из нее по решению Совета Ассоциации, принятому большинством голосов всех его Членов в случаях:
        <br />— нарушения положений действующего законодательства и настоящего Устава;
        <br />— неуплаты в установленные сроки или отказа от уплаты взносов;
        <br />— нанесения своими действиями (бездействием) ущерба Ассоциации и ее Членам;
        <br />— если деятельность Члена Ассоциации противоречит целям, задачам и направлениям деятельности Партнерства.
        Член Ассоциации, выходящий или исключенный из нее, имеет право на получение части имущества Ассоциации или стоимости этого имущества в пределах стоимости имущества, переданного им в собственность Ассоциации, за исключением членских взносов.</p>
      </section>
      <section id="7" ref={section7Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">7. Членские взносы и другие платежи.</div>
      <p>7.1. В целях обеспечения финансирования, подготовки и реализации отдельных проектов и целевых программ Члены Ассоциации уплачивают целевые и периодические взносы.</p>
      <p>7.2. Размер и порядок уплаты членских взносов определяется решениями Совета Ассоциации и в соответствии с внутренними документами Ассоциации.</p>
      </section>
      <section id="8" ref={section8Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">8. Права Ассоциации</div>
      <p>8.1. Ассоциация совершает любые действия, направленные на установление, изменение либо прекращение правоотношений, кроме запрещенных законодательством Российской Федерации; приобретает любое имущество, кроме изъятого из оборота или ограниченного в обороте; владеет, пользуется, распоряжается принадлежащим ему имуществом, включая здания, сооружения, помещения, строения, технику, оборудование, материалы, сырье, товары, услуги, «ноу-хау», ценные бумаги, финансовые ресурсы, другие материальные и интеллектуальные ценности;
        приобретает на правах собственности, полного хозяйственного ведения, арендует, принимает в дар, наследует имущество юридических и (или) физических лиц; владеет, пользуется и распоряжается имуществом на иных законных основаниях; а также продает, сдает в наем, в аренду, в т.ч. на основе лизинговых договоров, во временное или постоянное возмездное или безвозмездное пользование; обменивает, дарит, совершает с такого рода имуществом, материальными и интеллектуальными ценностями другие законные сделки; представляет интересы доверителей в суде, арбитражном суде и других правоохранительных и государственных органах.</p>
      <p>8.2. Ассоциация создает собственные и (или) с участием других отечественных и иностранных физических и (или) юридических лиц хозяйственные товарищества и общества, а также некоммерческие организации, в том числе с участием зарубежных инвестиций, юридических и (или) физических лиц.</p>
      <p>8.3. Ассоциация может создавать филиалы и открывать представительства на территории Российской Федерации в соответствии с законодательством Российской Федерации.</p>
      <p>8.4. Филиалом Ассоциации является ее обособленное подразделение, расположенное вне места нахождения Ассоциации и осуществляющее все ее функции или часть их, в том числе функции представительства.</p>
      <p>8.5. Представительством Ассоциации является обособленное подразделение, которое расположено вне места нахождения Ассоциации, представляет интересы Ассоциации и осуществляет ее защиту.</p>
      <p>8.6. Филиал и представительство Ассоциации не являются юридическими лицами, наделяются имуществом создавшего их Партнерства и действуют на основании утвержденного им положения. Имущество филиала или представительства учитывается на отдельном балансе и на балансе Ассоциации.
        Руководители филиала и представительства назначаются Ассоциацией и действуют на основании доверенности, выданной Ассоциацией.</p>
      <p>8.7. Филиал и представительство осуществляют деятельность от имени Ассоциации. Ответственность за деятельность своих филиала и представительства несет Ассоциация.
        Создание филиалов на территории других государств осуществляется с соблюдением условий, устанавливаемых законодательством этих государств и в соответствии с законодательством Российской Федерации.</p>
      </section>
      <section id="9" ref={section9Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">9. Имущество и средства Ассоциации</div>
      <p>9.1. Ассоциация может иметь в собственности или в оперативном управлении здания, сооружения, жилищный фонд, оборудование, инвентарь, денежные средства в рублях и иностранной валюте, ценные бумаги и иное имущество. Некоммерческая организация может иметь в собственности или в бессрочном пользовании земельные участки.</p>
      <p>9.2. Ассоциация отвечает по своим обязательствам тем своим имуществом, на которое по законодательству Российской Федерации может быть обращено взыскание.</p>
      <p>9.3. Источниками формирования имущества Ассоциации в денежной и иных формах являются:
        <br />— регулярные и единовременные поступления от Членов Партнерства;
        <br />— добровольные имущественные взносы и пожертвования;
        <br />— выручка от реализации товаров, работ, услуг;
        <br />— дивиденды (доходы, проценты), получаемые по акциям, облигациям, другим ценным бумагам и вкладам;
        <br />— доходы, получаемые от собственности некоммерческой организации;
        <br />— другие не запрещенные законом поступления.
        <br />Федеральным законом могут устанавливаться ограничения на источники доходов некоммерческих организаций отдельных видов.</p>
      <p>9.4. Полученная Ассоциацией прибыль не подлежит распределению между Членами Ассоциации.</p>
      <p>9.5. Размер и порядок уплаты Членами периодических и целевых взносов устанавливаются Собранием Членов Ассоциации.</p>
      <p>9.6. Взносы Членов Ассоциации, полученная Ассоциацией прибыль, а также все приобретенное ею за свой счет имущество являются собственностью Ассоциации.</p>
      <p>9.7. За счет взносов Членов и полученной прибыли Ассоциация создает различные фонды.
        Состав, назначение, размеры и порядок образования и направления расходования соответствующих фондов определяются Собранием Членов Ассоциации.</p>
      <p>9.8. В установленном законом порядке Ассоциация ведет бухгалтерскую и статистическую отчетность.</p>
      </section>
      <section id="10" ref={section10Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">10. Управление</div>
      <p>10.1. Органами управления Ассоциации являются:
        <br />— Собрание Членов Ассоциации;
        <br />— Совет Ассоциации;
        <br />— Президент.</p>
      </section>
      <section id="11" ref={section11Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">11. Собрание членов Ассоциации</div>
      <p>11.1. Высшим органом управления Ассоциации является Собрание Членов Ассоциации. Основной функцией Собрания Членов Ассоциации является обеспечение соблюдения Ассоциацией целей, в интересах которых она было создана.</p>
      <p>11.2. К компетенции Собрания Членов Ассоциации относится решение следующих вопросов:
        <br />1.) изменение Устава Ассоциации;
        <br />2.) определение приоритетных направлений деятельности Ассоциации, принципов формирования и использования ее имущества;
        <br />3.) образование исполнительных органов Ассоциации и досрочное прекращение их полномочий;
        <br />4.) утверждение годового отчета и годового бухгалтерского баланса;
        <br />5.) утверждение финансового плана некоммерческой организации и внесение в него изменений;
        <br />6.) создание филиалов и открытие представительств Ассоциации;
        <br />7.) участие Ассоциации в других организациях;
        <br />8.) реорганизация и ликвидация Ассоциации;
        <br />9.) избрание членов Ревизионной комиссии.
        <br />Вопросы, предусмотренные пп.2-4 и пп.8 настоящего пункта, относятся к исключительной компетенции Собрания Членов Ассоциации.</p>
        <p>11.3. Собрание Членов Ассоциации правомочно, если на нем присутствуют более половины ее Членов. Решение Собрания Членов Ассоциации принимается большинством голосов Членов, присутствующих на собрании. Решение Собрания Членов Ассоциации по вопросам исключительной компетенции принимается единогласно. Реорганизация Ассоциации путем слияния, присоединения, разделения, выделения может быть осуществлена по решению Собрания Членов Ассоциации, принятому большинством в ¾ голосов Членов Ассоциации; решение о преобразовании Ассоциации принимается Членами Ассоциации единогласно.</p>
        <p>11.4. Ассоциация не вправе осуществлять выплату вознаграждения ее Членам за выполнение ими возложенных на них функций, за исключением компенсации расходов, непосредственно связанных с участием в работе Собрания Членов Ассоциации.</p>
        <p>11.5. Члены Ассоциации письменно оповещаются о предстоящем Собрании не позднее чем за месяц до даты его проведения. В сообщении указываются время и место проведения Собрания и повестка дня.</p>
        <p>11.6. Собрание может принимать решения, не противоречащие действующему законодательству, по любым вопросам уставной деятельности Ассоциации.</p>
        <p>11.7. Каждый Член Ассоциации (его представитель) обладает одним голосом на Собрании вне зависимости от времени вступления в Ассоциацию и размеров вступительного взноса.</p>
        <p>11.8. Представители Членов Ассоциации действуют на Собраниях на основании доверенностей, выданных Членами Ассоциации, и могут быть постоянными либо назначенными на определенный срок. Член Ассоциации вправе в любое время заменить своего представителя, поставив в известность об этом Собрание Членов Ассоциации.
            <br />Без доверенности вправе участвовать в Собрании Членов Ассоциации руководители Членов Ассоциации -юридических лиц, имеющие уставное право представлять без доверенности данное юридическое лицо.</p>
            <p>11.9. Из числа Членов Ассоциации Собранием избирается Председатель Собрания. Председатель Собрания:
            <br />— руководит работой Собрания;
            <br />— председательствует на Собраниях;
            <br />— организует ведение протокола; книга протоколов должна быть в любое время предоставлена Членам Ассоциации и по их требованию должны выдаваться удостоверенные выписки из книги протоколов;
            <br />— подписывает протоколы Собраний и принятые на них решения.</p>
        <p>11.10. Собрание, как правило, решает вопросы открытым голосованием.</p>
        <p>11.11. Все Собрания, кроме годовых, являются внеочередными и собираются в случае, если этого требуют интересы Ассоциации в целом.
        <br />Внеочередное Собрание Членов Ассоциации может быть созвано по требованию Совета директоров. Президента, Председателя Собрания и Ревизионной комиссии.</p>
        <p>11.12. Собрания и принятые на них решения оформляются протоколами, которые подписываются Председателем Собрания и секретарем.</p>
      </section>
      <section id="12" ref={section12Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">12. Совет Ассоциации.</div>
      <p>12.1. Совет Ассоциации осуществляет общее руководство деятельностью Ассоциации, за исключением решения вопросов, отнесенных к исключительной компетенции Собрания Членов Ассоциации.</p>
      <p>12.2. Члены Совета Ассоциации избираются Собранием Членов Ассоциации сроком на один год. Количественный состав Совета Ассоциации определяется так же решением Собрания Членов Ассоциации. Лица, избранные в состав Совета Ассоциации, могут переизбираться неограниченное число раз. По решению Собрания Членов Ассоциации полномочия любого члена (всех членов) Совета Ассоциации могут быть прекращены досрочно.</p>
      <p>12.3. Председатель Совета Ассоциации избирается членами Совета Ассоциации из их числа большинством голосов от общего числа членов Совета Ассоциации. Совет Ассоциации вправе в любое время переизбрать своего председателя большинством голосов от общего числа членов Совета Ассоциации.</p>
      <p>12.4. Председатель Совета Ассоциации организует ее работу, созывает заседания Совета Ассоциации и председательствует на них, организует ведение протокола, председательствует на Собрании.</p>
      <p>12.5. В случае отсутствия Председателя Совета Ассоциации, его функции осуществляет один из членов Совета по решению Совета Ассоциации.</p>
      <p>12.6. К компетенции Совета Ассоциации относятся:
        <br />1) созыв годового и внеочередного Собраний Членов Ассоциации;
        <br />2) утверждение повестки дня Собраний Членов Ассоциации;
        <br />3) разработка и утверждение внутренних документов Ассоциации;
        <br />4) формирование комитетов, комиссий и рабочих групп для выполнения работ по отдельным программам и организационным вопросам ;
        <br />5) прием новых Членов Ассоциации и исключение из Членов Ассоциации;
        <br />6) определение размеров и порядка внесения целевых и периодических взносов Членов Ассоциации. Вопросы, отнесенные к компетенции Совета Ассоциации, не могут быть переданы на решение исполнительному органу Ассоциации.</p>
      <p>12.7. Заседание Совета Ассоциации созывается Председателем Совета Ассоциации по его инициативе, по требованию Членов Совета Ассоциации, Президента. Порядок созыва и проведения заседаний Совета Ассоциации определяется внутренними документами Ассоциации.</p>
      <p>12.8. Кворумом является присутствие более половины от числа избранных членов Совета Ассоциации.</p>
      <p>12.9. Решения на заседании Совета Ассоциации принимаются большинством голосов присутствующих. В случае равенства голосов голос Председателя Совета директоров является решающим.</p>
      <p>12.10. Каждый член Совета Ассоциации обладает одним голосом.</p>
      <p>12.11. Протокол заседаний Совета Ассоциации подписывается председательствующим на заседании, который несет ответственность за правильность составления протокола.</p>
      </section>
      <section id="13" ref={section13Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">13. Президент</div>
      <p>13.1. Текущее руководство деятельностью Ассоциации осуществляет Президент, компетенция которого определяется настоящим Уставом. Президент избирается Собранием Членов Ассоциации сроком на два года. Президент является единоличным исполнительным органом управления Ассоциации.</p>
      <p>13.2. Президент несет ответственность за выполнение уставных целей и задач Ассоциации, а также за выполнение решений Собрания Членов Ассоциации.</p>
      <p>13.3. Президент самостоятельно решает другие вопросы деятельности Ассоциации, кроме отнесенных к компетенции Собрания Членов Ассоциации.</p>
      <p>13.4. Президент:
        <br />— без доверенности представляет Ассоциацию в рамках своей компетенции во взаимоотношениях с другими юридическими и (или) физическими лицами, выдает доверенности, заключает договоры (контракты), в том числе трудовые, издает распоряжения и дает указания, обязательные для руководящих работников и персонала Ассоциации;
        <br />— в рамках своей компетенции пользуется правом распоряжения имуществом и средствами Ассоциации, подписи банковских и финансовых документов;
        <br />— осуществляет оперативное руководство текущей деятельностью Ассоциации;
        <br />— утверждает должностные инструкции для штатных работников Ассоциации;
        <br />— руководит хозяйственной деятельностью Ассоциации, организует ее работу и несет полную ответственность за соблюдение финансовой, договорной и трудовой дисциплины;
        <br />— списывает с баланса Ассоциации безнадежные и просроченные к взысканию задолженности, недостачи и потери товарно-материальных ценностей, морально устаревшего, изношенного и непригодного оборудования, если иной порядок не предусмотрен законодательством Российской Федерации.</p>
      <p>13.5. Президент подотчетен Собранию Членов Ассоциации.</p>
      </section>
      <section id="14" ref={section14Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">14. Ревизионная комиссия</div>
      <p>14.1. Ревизионная комиссия избирается Собранием Членов Ассоциации сроком на 2 года из числа Членов Ассоциации.</p>
      <p>14.2. В состав Ревизионной комиссии не может входить Президент Ассоциации.</p>
      <p>14.3. Ревизионная комиссия проводит ежегодно плановые ревизии и отчитывается перед Собранием Членов Ассоциации. Годовой отчет может быть утвержден Собранием Членов Ассоциации только после его проверки Ревизионной комиссией.</p>
      <p>14.4. Проверки финансово-хозяйственной деятельности Ассоциации производятся Ревизионной комиссией по поручению Собрания Членов Ассоциации и собственной инициативе.</p>
      <p>14.5. Ревизионная комиссия вправе требовать от должностных лиц Ассоциации предоставления им всех необходимых материалов, бухгалтерских отчетов или иных документов и личных объяснений.</p>
      <p>14.6. Ревизионная комиссия обязана потребовать созыва Внеочередного Собрания Членов Ассоциации, если возникла угроза существенным интересам Ассоциации.</p>
      </section>
      <section id="15" ref={section15Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">15. Учет и отчетность</div>
      <p>15.1. Ассоциация осуществляет бухгалтерский, оперативный и статистический учет и отчетность в порядке, определенном законодательными актами Российской Федерации. Организация документооборота устанавливается Президентом.</p>
      <p>15.2. Контроль за соблюдением установленного порядка осуществления финансовой деятельности осуществляют государственные финансовые (налоговые) органы по месту нахождения Ассоциации.</p>
      <p>15.3. Ассоциация предоставляет информацию о своей деятельности органам государственной статистики и налоговым органам, Членам Ассоциации и иным заинтересованным лицам в соответствии с законодательством Российской Федерации и учредительными документами Ассоциации.</p>
      <p>15.4. Размеры и структура доходов Ассоциации, а также сведения о размерах и составе имущества Ассоциации, о его расходах, численности и составе работников, об оплате их труда, об использовании безвозмездного труда граждан в деятельности Партнёрства не могут быть предметом коммерческой тайны.</p>
      <p>15.5. Операционно-хозяйственный год Ассоциации считается с 1 января по 31 декабря календарного года.</p>
      </section>
      <section id="16" ref={section16Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">16 Архив, работа с документами</div>
      <p>16.1. В целях реализации государственной социальной, экономической и налоговой политики Ассоциация несет ответственность за сохранность документов (управленческих, финансово-хозяйственных, по личному составу и др.); обеспечивает передачу на государственное хранение документов, имеющих научно-историческое значение, в Центральные архивы Москвы в соответствии с перечнем документов, согласованным с объединением «Мосгорархив»; хранит и использует в установленном порядке документы по личному составу.</p>
      <p>16.2. Ассоциация хранит документы, предусмотренные п. 16.1. настоящего Устава по месту нахождения его исполнительного органа, а именно по адресу: 125009, г. Москва, Газетный переулок, д. 9, корпус 4, офис 305</p>
      <p>16.3. По требованию Члена Ассоциации, аудитора или любого заинтересованного лица Ассоциация обязано в разумные сроки предоставить им возможность ознакомиться с учредительными документами Ассоциации, в том числе с изменениями. Ассоциация обязана по требованию Члена Ассоциации предоставить ему копии действующего Устава Ассоциации. Плата, взимаемая Ассоциацией за предоставление копий, не может превышать затраты на их изготовление.</p>
      </section>
      <section id="17" ref={section17Ref}>
      <div className="title is-font-title-regular has-text-left has-text-var-blue pt-6">17. Реорганизация и ликвидация</div>
      <p>17.1. Ассоциация может быть реорганизована или ликвидирована в порядке, предусмотренном Гражданским кодексом Российской Федерации, Федеральным законом «О некоммерческих организациях» и другими федеральными законами.</p>
      <p>17.2. Реорганизация может быть осуществлена в форме слияния, присоединения, выделения и преобразования.</p>
      <p>17.3. Реорганизация Ассоциации путем слияния, присоединения, разделения, выделения может быть осуществлена по решению Собрания Членов Ассоциации, принятому большинством в ¾ голосов Членов Ассоциации;
        решение о преобразовании Ассоциации принимается Членами Ассоциации единогласно.</p>
        <p>17.4. Ассоциация считается реорганизованной, за исключением случаев реорганизации в форме присоединения, с момента государственной регистрации вновь возникшей организации (организаций).
        <br />При реорганизации Ассоциации в форме присоединения к нему другой организации Ассоциация считается реорганизованной с момента внесения в единый государственный реестр юридических лиц записи о прекращении деятельности присоединенной организации.</p>
      <p>17.5. Государственная регистрация вновь возникшей в результате реорганизации организации (организаций) и внесение в единый государственный реестр юридических лиц записи о прекращении деятельности реорганизованной Ассоциации осуществляются в порядке, установленном законом о государственной регистрации юридических лиц.</p>
      <p>17.6. Ассоциации вправе преобразоваться в общественную или религиозную организацию (объединение), фонд или автономную некоммерческую организацию.</p>
      <p>17.7. При преобразовании Ассоциации к вновь возникшей организации переходят права и обязанности реорганизованного Ассоциации в соответствии с передаточным актом.</p>
      <p>17.8. Члены Ассоциации или орган, принявший решение о ликвидации Ассоциации, назначают по согласованию с органом, осуществляющим государственную регистрацию юридических лиц, ликвидационную комиссию (ликвидатора) и устанавливают в соответствии с Гражданским кодексом Российской Федерации и настоящим Федеральным законом порядок и сроки ликвидации Ассоциации.</p>
      <p>17.9. С момента назначения ликвидационной комиссии к ней переходят полномочия по управлению делами Ассоциации. Ликвидационная комиссия от имени ликвидируемой Ассоциации выступает в суде.</p>
      <p>17.10. Ликвидационная комиссия помещает в органах печати, в которых публикуются данные о государственной регистрации юридических лиц, публикацию о ликвидации Ассоциации, порядке и сроке заявления требований его кредиторами.
        <br />Срок заявления требований кредиторами не может быть менее чем два месяца со дня публикации о ликвидации Ассоциации.</p>
        <p>17.11. Ликвидационная комиссия принимает меры по выявлению кредиторов и получению дебиторской задолженности, а также уведомляет в письменной форме кредиторов о ликвидации Ассоциации.</p>
        <p>17.12. По окончании срока для предъявления требований кредиторами ликвидационная комиссия составляет промежуточный ликвидационный баланс, который содержит сведения о составе имущества ликвидируемой Ассоциации, перечне предъявленных кредиторами требований, а также о результатах их рассмотрения.
        <br />Промежуточный ликвидационный баланс утверждается Членами Ассоциации или органом, принявшим решение о его ликвидации, по согласованию с органом, осуществляющим государственную регистрацию юридических лиц.</p>
      <p>17.13. Если имеющиеся у ликвидируемой Ассоциации денежные средства недостаточны для удовлетворения требований кредиторов, ликвидационная комиссия осуществляет продажу имущества Ассоциации с публичных торгов в порядке, установленном для исполнения судебных решений.</p>
      <p>17.14. Выплата денежных сумм кредиторам ликвидируемой Ассоциации производится ликвидационной комиссией в порядке очередности, установленной Гражданским кодексом Российской Федерации, в соответствии с промежуточным ликвидационным балансом начиная со дня его утверждения, за исключением кредиторов пятой очереди, выплаты которым производятся по истечении месяца со дня утверждения промежуточного ликвидационного баланса.</p>
      <p>17.15. После завершения расчетов с кредиторами ликвидационная комиссия составляет ликвидационный баланс, который утверждается Членами Ассоциации или органом, принявшим решение о ликвидации Ассоциации, по согласованию с органом, осуществляющим государственную регистрацию юридических лиц.</p>
      <p>17.16. Ликвидация Ассоциации влечет ее прекращение без перехода прав и обязанностей в порядке правопреемства к другим лицам.</p>
      <p>17.17. При ликвидации Ассоциации оставшееся после удовлетворения требований кредиторов имущество подлежит распределению между Членами Ассоциации в соответствии с их имущественным взносом, размер которого не превышает размер их имущественных взносов, если иное не установлено федеральными законами.
        <br />Имущество Ассоциации, стоимость которого превышает размер имущественных взносов его Членов, направляется в соответствии с учредительными документами Ассоциации на цели, в интересах которых оно было создано, и (или) на благотворительные цели. В случае, если использование имущества ликвидируемой Ассоциации в соответствии с ее учредительными документами не представляется возможным, оно обращается в доход государства.</p>
      <p>17.18. Ликвидация Ассоциации считается завершенной, а Ассоциация -прекратившей существование после внесения об этом записи в единый государственный реестр юридических лиц.</p>
      <p>17.19. При реорганизации Ассоциации все документы (управленческие, финансово-хозяйственные, по личному составу и др.) передаются в соответствии с установленными правилами правопреемнику в соответствии с передаточным актом или разделительным балансом.</p>
      <p>17.20. При ликвидации Ассоциации документы постоянного хранения, имеющие научно-историческое значение, передаются на государственное хранение в архивы объединения «Мосгорархив»; документы по личному составу (приказы, личные дела и карточки учета, лицевые счета и др.) передаются на хранение в архив административного округа, на территории которого находится Ассоциации. Передача и упорядочение документов осуществляется силами и за счет средств Ассоциации в соответствии с требованиями архивных органов.</p>
      </section>
      </div>
      </div>
    );
  }

  export default Statute;
