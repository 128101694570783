

function ArticlePage07() {

    return (
      <div className="xContent p-box py-6">
        <h1 class="title is-font-title-regular has-text-centered has-text-var-blue">Зачем тебе нужна система эмоциональных продаж?</h1>
        <div className="content wysiwyg">
        <p className='has-text-centered p-style p-1'>Система эмоциональных продаж тебе нужна для того, чтобы твой бизнес развивался и был прибыльным. Без нее, скорее всего, он долго не протянет.</p>
        <p>Почему?</p>
        <p className='p-1'>— Из-за изменений в рыночной конъюнктуре, покупателях, продавцах и в информации.</p>
        <p className='p-1'>Конкуренция стала настолько высокой, что дифференцироваться товаром или оформлением точек продаж уже практически невозможно. Оформление магазинов подтягивается к единому «ровному» уровню, производители выпускают сопоставимые по параметрам товары, развитие онлайн торговли увеличивает ценовое давление, а маркетологи используют в рекламных коммуникациях одни и те же уникальные торговые предложения.</p>
        <p className='p-1'>Одновременно с этим покупатели стали покупать совсем не то, что производят или закупают у других поставщиков продавцы. Charles Revson, основавший косметическую компанию Revlon, раньше многих осознал это и сформулировал свое наблюдение так: «на фабриках мы производим парфюмерию, а в магазинах мы продаем надежду». Вдумайся: никто из покупателей духов не покупает уникальную рецептуру, которая может быть описана как „20% цитрусового аромата, 54% — сандалового дерева и оставшееся – ароматы полевых цветов“. </p>
        <p>Ты продаешь мотоциклы?</p>
        <p className='p-1'>— Тогда у тебя покупают ощущение свободы.</p>
        <p>Пиво?</p>
        <p className='p-1'>— У тебя покупают тусовку и общество.</p>
        <p>Спортивные или люксовые автомобили?</p>
        <p className='p-1'>— Покупают восторг от ускорения и статус.</p>
        <p>Промышленное оборудование?</p>
        <p className='p-1'>— Уверенность в эффективной работе производства.</p>
        <p className='p-1'>Чем бы ни занималась твоя компания, твои покупатели платят, прежде всего, не за продуктовые характеристики, а за эмоции.</p>
        <p className='p-1'>Покупатели стали более требовательными, они совершают меньше импульсных покупок и стараются получить максимум за свои деньги, но при этом нуждаются во внимании и качественном общении, которого им не хватает в повседневной жизни.</p>
        <p className='p-1'>Продавцы-консультанты не ценят свою работу, не хотят проявлять инициативу, устанавливать личный контакт и формировать долгосрочные отношения с покупателями. Они не хотят меняться под меняющуюся рыночную конъюнктуру, что негативно влияет на динамику продаж в оффлайн сегменте. Существующие распространенные скрипты продаж устарели и зачастую даже вредят продажам.</p>
        <p className='p-1'>Если тебе довелось в последние несколько лет покупать автомобиль, то наверняка в процессе продажи продавец-консультант задавал тебе вопрос: «Знакомы с автомобилем?». И, скорее всего, Вы ответили: „Да“. Но что скрывалось за этим „да“ и помог ли этот ответ продавцу-консультанту? Это означало, что у тебя такой автомобиль уже есть или был, но предыдущей модели? Что такой у твоего родственника? Друга? Что ты его видел в интернете? Или — 10 раз брал тест-драйв? Очевидно, что такой вопрос не является эффективным и он, на самом деле, не позволяет понять потребности покупателя, но продавцы не стремятся изменить процессы для того, чтобы стать эффективнее. Такое встречается практически во всех отраслях.</p>
        <p className='p-1'>Осуществлению необходимых изменений мешает подход к работе у продавцов: они считают, что раз пришли на работу, то работодатель им уже, безусловно, «должен». Как минимум за то, что они пришли. И следующие восемь часов будут на рабочем месте, с „небольшими“ отвлечениями на социальные сети и мессенджеры, ланчи и кофе брейки, а также перекуры.</p>
        <p className='p-1'>Продавцы не хотят подниматься со своего рабочего места. Те, кто продают грузовую технику, не поедут к клиенту и не будут инициативно звонить потенциальным покупателям: они будут работать на входящем трафике, используя устаревшие однообразные скрипты, не делающие предложение уникальным.</p>
        <p className='p-1'>Вместо энтузиазма они демонстрируют лень и усталость, многие жалуются на текучку и пассивный подход к продажам. Бывает ли у тебя такое, что продавцы-консультанты ведут себя пассивно? Кажется, будто они считают, что «им должны» уже за то, что они просто приехали на работу?</p>
        <p className='p-1'>Доступность информации, во многих случаях, не упростила покупателям выбор, а наоборот, сделала его сложнее. Более того, именно избыток информации иногда приводит к тому, что покупатели откладывают решения о покупках, поскольку не могут разобраться во всех нюансах.</p>
        <p className='p-1'>Каких-то 10-15-20-25 лет назад можно было выигрывать в борьбе за покупателя просто на просто предоставляя ему адекватный товар или услугу. Рост конкуренции заставил компании думать о своем позиционировании, поиске уникальных элементов предложения.</p>
        <p className='p-1'>Это привело к тому, что в каждой нише появилось множество компаний, предлагающих идентичные товары со схожим позиционированием. Например, почти все производители моторных масел «говорят» о таких преимуществах, как снижение расхода топлива, а также очищении и продлении жизни двигателя. Находить неиспользуемое УТП в рациональном поле стало все сложнее, но даже если удается найти рациональное уникальное торговое предложение, не задействованное другими, нет гарантии того, что оно будет важным преимуществом и притягивающим покупателей элементом внешней коммуникации. </p>
        <p className='p-1'>По множеству указанных выше причин бизнесу с каждым годом, месяцем, и даже днем, продавать становится все сложнее, а в арсенале предпринимателей и продавцов остается только одна новая технология, которая действительно увеличивает продажи и удовлетворенность покупателей меньшими усилиями. Эта технология называется «эмоциональные продажи».</p>
        <p className='p-1'>Суть метода заключается в кратном увеличении ценности покупаемого товара и усилении желания его приобрести настолько, что барьеры или сомнения, препятствующие покупке, становятся мало значимыми.</p>
        <p className='p-1'>Это достигается за счет особых подходов искреннего и компетентного взаимодействия с покупателями на различных этапах: от приветствия и выявления потребностей до презентации товара, закрытия сделки и последующих действий.</p>
        <p>Эмоциональные продажи позволят тебе решить следующие задачи:</p>
        <ul className='p-1'><li>Системно приводить убедительные финальные аргументы в пользу совершения сделки здесь и сейчас</li>
        <li>Сформулировать безусловную точку отличия от конкурентов</li>
        <li>Построить долгосрочные отношения с покупателями (может конвертироваться в повторные продажи)</li>
        <li>Увеличить конверсию из потенциальных клиентов в покупателей </li>
        <li>Увеличить средний чек</li>
        <li>Сделать покупателей более лояльными и ценящими продавцов</li>
        <li>Увеличить уровни энтузиазма и мотивации продавцов</li></ul>
        <p className='p-1'>Коммуникационные приемы в эмоциональных продажах, предполагающие использование определенных методик, основанных на понимании истинных потребностей покупателей и их стиля жизни, делают консультацию ярким впечатлением. </p>
        <p className='p-1'>Любые изменения в работе отделов продаж сопряжены с сопротивлением, однако внедрение техник эмоциональных продаж практически не встречает сопротивления как раз потому, что она позволяет продавцам-консультантам тратить меньше усилий для каждой сделки и увеличивать вероятность повторных продаж, что соответствует их внутренним установкам. Это работает как в В2В, так и в В2С сегментах.</p>
        </div>
      </div>
    );
  }

  export default ArticlePage07;
